import BaseAsset from "@/model/general-assets/BaseAsset";
import CDNService from "@/services/CDNService";
import PromiseLoader from "../PromiseLoader/PromiseLoader";
// import "./CDNPDFViewer.scss";
import PDFViewer, { PDFHighlight } from "./PDFViewer";

interface CDNPDFViewerProps {
  asset: BaseAsset;
  cdnID: string;
  assetField: string;
  assetType: string;

  identifier?: string;
  height?: number | string;
  highlights?: PDFHighlight[];
  download?: boolean;
  filename: string;
  scrollGroup?: string;
  scrollIdentifier?: string;
  zoomFactorStorageKey?: string;
  border?: boolean;
}
const CDNPDFViewer = (props: CDNPDFViewerProps) => {
  const { asset, assetField, assetType, cdnID, ...other } = props;

  const fileKey = asset.cdn.find((e) => e.id === cdnID)?.key;
  return (
    <PromiseLoader
      promise={(param) =>
        CDNService.fetchCDNLink({
          assetField: props.assetField,
          assetId: props.asset?._id,
          assetType: props.assetType,
          cdnId: props.cdnID,
          hasFolderReadPermissions: true,
          fileKey,
        })
      }
      render={(url) => <PDFViewer {...other} url={url} />}
    />
  );
};

export default CDNPDFViewer;
