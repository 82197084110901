import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import StringUtils from "@/utils/StringUtils";
import { RentalAgreementOptionBy } from "../../../CBRentalUtils";
import { AgreementOption, RentalAgreement } from "../../../TenantsInterfaces";
import { CBRentalAgreementOptionUpdateForm } from "../form-components/CBRentalAgreementOptionForm";
// import "./CBAgreementOption.scss";

export type OptionType = {
  option: null | AgreementOption[];
};
interface CBAgreementOptionProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  data: OptionType;

  hideIfEmpty?: boolean;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementOption = (props: CBAgreementOptionProps) => {
  if (props.hideIfEmpty && (props.data.option || []).length === 0) {
    return null;
  }

  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={
        <>
          <span className={`padding-right-5`}>
            {i18n.t("cb:RentalAgreement.Form.Sections.option.title", "Option")}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "sm",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBRentalAgreementOptionUpdateForm
                        rentalAgreement={props.rentalAgreement}
                        onClose={close}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.option.viewHint",
        "Informationen zur Option"
      )}
      identifier="option"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      {props.data.option ? (
        <div>
          {props.data.option.map((option, index) => (
            <div className={`option-entry`}>
              <div className={`title`}>
                {i18n.t("cb:RentalAgreement.Labels.Option.option", "Option")}{" "}
                {index + 1}
              </div>

              <div className={`__flex-wrap padding-bottom-10`}>
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Option.until",
                    "Option bis"
                  )}
                  value={option.optionUntil}
                  formatter={StringUtils.formatDate}
                />
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Option.optionBy",
                    "Option von"
                  )}
                  value={option.optionBy}
                  formatter={(value) =>
                    RentalAgreementOptionBy().find((e) => e.value === value)
                      ?.label
                  }
                />
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Option.extendsByMonths",
                    "Verlängerung um"
                  )}
                  value={option.extendsByMonths}
                  formatter={(value) =>
                    `${value} ${i18n.t("Global.Labels.months", "Monate")}`
                  }
                />
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Option.optionsUsed",
                    "Option verwendet"
                  )}
                  value={StringUtils.formatBoolean(option.optionUsed)}
                />
              </div>

              <div className={`__flex`}>
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Option.note",
                    "Notiz"
                  )}
                  value={option.note}
                  formatter={(value) => <WrappedText text={value} />}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={`__empty no-padding`}>
          {i18n.t(
            "cb:RentalAgreement.Labels.NoOption",
            "Keine Option hinterlegt"
          )}
        </div>
      )}
    </BFPDFMarkerSection>
  );
};

export default CBAgreementOption;
