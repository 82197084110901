import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { ReactNode } from "react";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import { renderCellValue } from "../../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import LanguageService from "../../../../../../../services/LanguageService";
import { isDefined } from "../../../../../../../utils/Helpers";
import StringUtils from "../../../../../../../utils/StringUtils";
import {
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import { RentalUnit } from "../../TenantsInterfaces";
import "./CBRentalAgreementHelper.scss";

export const RentalUnitTableHeader = (props: { endNode?: ReactNode }) => {
  return (
    <div className={`cb-rental-agreement-rental-unit-entry header-row`}>
      <div className={`displayName column`}>
        {i18n.t("cb:RentalUnit.displayName", "Name")}
      </div>
      <div className={`rentalStatus column`}>
        {i18n.t("cb:RentalUnit.rentalStatus", "Status")}
      </div>
      <div className={`usage-type column`}>
        {i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart")}
      </div>
      <div className={`type column`}>
        {i18n.t("cb:RentalUnit.unitType", "Art")}
      </div>

      <div className={`building column`}>
        {i18n.t("cb:RentalUnit.building", "Gebäude")}
      </div>
      <div className={`floor column`}>
        {i18n.t("cb:RentalUnit.floor", "Etage")}
      </div>
      <div className={`quantity column`}>
        {i18n.t("cb:RentalUnit.quantity", "Fläche/Anzahl")}
      </div>
      <div className={`rentGross column`}>
        {i18n.t("cb:RentalUnit.rentGross", "Planmiete brutto")}
      </div>
      <div className={`rentNet column`}>
        {i18n.t("cb:RentalUnit.rentNet", "Planmiete netto")}
      </div>
      <div className={`operatingCostGross column`}>
        {i18n.t("cb:RentalUnit.operatingCostGross", "Plan-Nebenkosten brutto")}
      </div>
      <div className={`operatingCostNet column`}>
        {i18n.t("cb:RentalUnit.operatingCostNet", "Plan-Nebenkosten netto")}
      </div>
      {props.endNode}
    </div>
  );
};

export const RentalUnitTableEntry = (props: {
  rentalUnit: RentalUnit;
  asLink?: boolean;
  endNode?: ReactNode;
}) => {
  const unit = ObjectKindStruct.getUnitTypeBy(props.rentalUnit.data.unitType);

  if (!unit) return null;
  const rentalStatus = getConfigRentalStatus(
    props.rentalUnit.data.rentalStatus
  );
  const status = getConfigRentalUnitTypeGroup(unit.group);

  const unitType = ObjectKindStruct.getUnitTypeBy(
    props.rentalUnit.data.unitType
  );
  const group = getConfigRentalUnitTypeGroup(unitType?.group);
  return (
    <div className={`cb-rental-agreement-rental-unit-entry`}>
      <div className={`displayName column`}>
        <DebugDataComponent data={props.rentalUnit} />
        {props.asLink ? (
          <BFDetailsButton
            noPadding
            size="xs"
            appearance="link"
            data={{
              assetType: AssetTypes.Rental.RentalUnit,
              assetId: props.rentalUnit._id,
              type: props.rentalUnit.data.type,
            }}
          >
            {[
              props.rentalUnit.data.id,
              LanguageService.translateLabel(props.rentalUnit.data.displayName),
            ]
              .filter((e) => isDefined(e))
              .join(" - ")}
          </BFDetailsButton>
        ) : (
          [
            props.rentalUnit.data.id,
            LanguageService.translateLabel(props.rentalUnit.data.displayName),
          ]
            .filter((e) => isDefined(e))
            .join(" - ")
        )}
      </div>
      <div className={`rentalStatus column`}>
        {rentalStatus && (
          <BFStatus
            size="xs"
            color={rentalStatus.color}
            label={rentalStatus.label}
          />
        )}
      </div>
      <div className={`usage-type column`}>
        {status && (
          <BFStatus size="xs" color={status.color} label={status.label} />
        )}
      </div>
      <div className={`type column`}>
        {renderCellValue(
          ObjectKindStruct.getUnitTypeBy(props.rentalUnit.data.unitType)
            ?.displayName,
          "-",
          (value) => LanguageService.translateLabel(value)
        )}
      </div>
      <div className={`building column`}>
        {renderCellValue(props.rentalUnit.data?.building, "-")}
      </div>
      <div className={`floor column`}>
        {renderCellValue(props.rentalUnit.data?.floor, "-")}
      </div>
      <div className={`quantity column`}>
        {group?.areaType === "area"
          ? renderCellValue(props.rentalUnit.data?.area, "-", (value) =>
              StringUtils.formatArea(value)
            )
          : renderCellValue(props.rentalUnit.data?.quantity, "-")}
      </div>

      <div className={`column rentGross`}>
        {renderCellValue(
          props.rentalUnit.data?.rentGross,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        )}
      </div>

      <div className={`column rentNet`}>
        {renderCellValue(props.rentalUnit.data?.rentNet, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        )}
      </div>

      <div className={`column operatingCostGross`}>
        {renderCellValue(
          props.rentalUnit.data?.operatingCostGross,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        )}
      </div>

      <div className={`column operatingCostNet`}>
        {renderCellValue(
          props.rentalUnit.data?.operatingCostNet,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        )}
      </div>
      {props.endNode}
    </div>
  );
};
