import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import FormWizard from "@/components/Form/FormWizard/FormWizard";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import DataBusDefaults from "@/services/DataBusDefaults";
import CBRentalService from "../../../CBRentalService";
import { CB_RENTAL_AGREEMENT_LIST } from "../../../CBTenantsConst";
import { RentalAgreement } from "../../../TenantsInterfaces";
import CBRentalAgreementFormWizardStep1UploadContract, {
  CBRentalAgreementFormWizardStep1Value,
} from "./CBRentalAgreementFormWizardStep1UploadContract";
import CBRentalAgreementFormWizardStep2ContractPartners, {
  CBRentalAgreementFormWizardStep2Value,
} from "./CBRentalAgreementFormWizardStep2ContractPartners";
import CBRentalAgreementFormWizardStep3RentalUnits, {
  CBRentalAgreementFormWizardStep3Value,
} from "./CBRentalAgreementFormWizardStep3RentalUnits";
import CBRentalAgreementFormWizardStep4ContractData, {
  CBRentalAgreementFormWizardStep4Value,
} from "./CBRentalAgreementFormWizardStep4ContractData";
import CBRentalAgreementFormWizardStep5Overview, {
  CBRentalAgreementFormWizardStep5Value,
} from "./CBRentalAgreementFormWizardStep5Overview";
// import "./CBRentalAgreementFormWizard.scss";

interface CBRentalAgreementFormWizardProps {
  kind: ObjectKind;
  forObject?: string;
  onClose: () => void;

  onSuccess?: (agreement: RentalAgreement) => void;
}
const CBRentalAgreementFormWizard = (
  props: CBRentalAgreementFormWizardProps
) => {
  return (
    <FormWizard
      initialValues={[
        {},
        {
          ...(props.forObject
            ? {
                objectId: props.forObject,
                entity: OrgaStruct.getObject(props.forObject)?.entityId,
              }
            : {}),
        },
        {},
        {
          rentPaymentTimingOptions: "startOfMonth",
        },
        {},
      ]}
      entries={[
        {
          render: (getState, finish, goBack, saveValue) => {
            const { current, previous } = getState();
            return (
              <CBRentalAgreementFormWizardStep1UploadContract
                initialValues={current}
                onBack={() => {
                  props.onClose();
                }}
                onSuccess={async (values) => {
                  await finish(values);
                }}
              />
            );
          },
        },
        {
          render: (getState, finish, goBack, saveValue) => {
            const { current, previous } = getState();
            return (
              <CBRentalAgreementFormWizardStep2ContractPartners
                data={{
                  step1: previous[0],
                }}
                onValueChange={saveValue}
                kind={props.kind}
                initialValues={current}
                onBack={() => {
                  goBack();
                }}
                onSuccess={async (values) => {
                  await finish(values);
                }}
              />
            );
          },
        },
        {
          render: (getState, finish, goBack, saveValue) => {
            const { current, previous } = getState();
            return (
              <CBRentalAgreementFormWizardStep3RentalUnits
                kind={props.kind}
                data={{
                  step1: previous[0],
                  step2: previous[1],
                }}
                onValueChange={saveValue}
                initialValues={current}
                onBack={() => {
                  goBack();
                }}
                onSuccess={async (values) => {
                  await finish(values);
                }}
              />
            );
          },
        },
        {
          render: (getState, finish, goBack, saveValue) => {
            const { current, previous } = getState();
            return (
              <CBRentalAgreementFormWizardStep4ContractData
                data={{
                  step1: previous[0],
                  step2: previous[1],
                  step3: previous[2],
                }}
                onValueChange={saveValue}
                initialValues={current}
                onBack={() => {
                  goBack();
                }}
                onSuccess={async (values) => {
                  await finish(values);
                }}
              />
            );
          },
        },
        {
          render: (getState, finish, goBack, saveValue) => {
            const { current, previous } = getState();
            return (
              <CBRentalAgreementFormWizardStep5Overview
                kind={props.kind}
                data={{
                  step1: previous[0],
                  step2: previous[1],
                  step3: previous[2],
                  step4: previous[3],
                }}
                initialValues={current}
                onBack={() => {
                  goBack();
                }}
                onSuccess={async (values) => {
                  await finish(values);
                }}
              />
            );
          },
        },
      ]}
      onSubmit={async (
        values: [
          CBRentalAgreementFormWizardStep1Value,
          CBRentalAgreementFormWizardStep2Value,
          CBRentalAgreementFormWizardStep3Value,
          CBRentalAgreementFormWizardStep4Value,
          CBRentalAgreementFormWizardStep5Value
        ]
      ) => {
        // ModalManager.show({
        //   content: (state, setState, close) => (
        //     <div>

        //       CFAttachmentEntry
        //     </div>
        //   )
        // })
        const fullValue = {
          type: props.kind.data.type,
          ...values[0],
          ...values[1],
          ...values[2],
          ...values[3],
          ...values[4],
          marks: {
            ...(values[1].marks || {}),
            ...(values[2].marks || {}),
            ...(values[3].marks || {}),
          },
        };

        const rentalAgreementDirectoryId = (
          props.kind.data.features.find(
            (e) => e.type === "immo"
          ) as ObjectKindFeature_IMMO
        )?.rentalAgreementDirectory;
        const depositDirectoryId = (
          props.kind.data.features.find(
            (e) => e.type === "immo"
          ) as ObjectKindFeature_IMMO
        )?.rentalAgreementDepositDirectory;

        const result = (await CBRentalService.submitRentalAgreement(
          props.kind,
          fullValue,
          {
            directoryId: rentalAgreementDirectoryId,
          },
          {
            directoryId: depositDirectoryId,
          }
        )) as RentalAgreement;

        DataBusDefaults.reload({
          identifiers: [CB_RENTAL_AGREEMENT_LIST],
        });

        ModalManager.success({
          title: i18n.t(
            "cb:RentalAgreement.Form.Wizard.Success.Title",
            "Mietvertrag erfolgreich erstellt"
          ),
          message: i18n.t(
            "cb:RentalAgreement.Form.Wizard.Success.Message",
            "Der Mietvertrag wurde erfolgreich erstellt. Sie werden nun zur dem erstellten Mietvertrag weitergeleitet."
          ),
          buttonText: i18n.t("Global.Buttons.continue"),
          onOk: () => {
            props.onSuccess?.(result);
          },
          onClose: () => {
            props.onClose();
          },
        });
      }}
    />
  );
};

export default CBRentalAgreementFormWizard;
