import _ from "lodash";
import i18n from "../../i18n";
import { Contact, ContactType } from "../../model/db/Contact";
import { BFChooserOption } from "../abstract-ui/forms/chooser/BFChooser";

class ContactUtilsClass {
  getContactTypes: () => ContactType[] = () => [
    "EMPLOYEE",
    "INVOICE",
    "TENANT",
    "OTHER",
    "TRANSIENT",
  ];
  reduceContactTypes = (contactTypes: ContactType[]) => {
    return _.uniq(
      contactTypes.map((tag) => {
        if (tag === "TRANSIENT") return "OTHER";

        return tag;
      })
    );
  };
  getContactTypeName(contactType: ContactType) {
    switch (contactType) {
      case "EMPLOYEE":
        return i18n.t("Contact.Type.EMPLOYEE", "Mitarbeiter");
      case "INVOICE":
        return i18n.t("Contact.Type.INVOICE", "Rechnungsteller");
      case "TENANT":
        return i18n.t("Contact.Type.TENANT", "Mieter");
      case "BILL":
        return i18n.t("Contact.Type.BILL", "Kunde");
      case "OTHER":
        return i18n.t("Contact.Type.OTHER", "Weitere");
      case "TRANSIENT":
        return i18n.t("Contact.Type.TRANSIENT", "Vorläufig");
      default:
        return contactType;
    }
  }
  getSalutationString = (salutationData: {
    personType: "organization" | "private";
    salutation?: "f" | "m" | "d";
    firstName?: string;
    lastName?: string;
  }) => {
    const { personType, salutation, firstName, lastName } = salutationData;

    if (personType === "organization") {
      return i18n.t(
        "Salutation.organization",
        "Sehr geehrte Damen und Herren,"
      );
    }
    let greeting = "";

    // Determine the formal salutation based on the gender
    if (salutation === "m") {
      greeting = i18n.t("Salutation.privateMale", "Sehr geehrter Herr");
    } else if (salutation === "f") {
      greeting = i18n.t("Salutation.privateFemale", "Sehr geehrte Frau");
    } else {
      greeting = i18n.t("Salutation.privateDivers", "Guten Tag");
    }
    // Append last name if available
    if (firstName) {
      // Otherwise, append first name if no last name is provided
      greeting += ` ${firstName}`;
    }
    if (lastName) {
      greeting += ` ${lastName}`;
    }
    // If neither name is provided, leave it generic

    return greeting;
  };
  getInfoLine = (contact: Contact) => {
    let infoLines: string[] = [];

    if (contact.data.address?.length > 0) {
      const firstAddress = contact.data.address[0];
      if (firstAddress.zip && firstAddress.city) {
        infoLines.push(`${firstAddress.zip} ${firstAddress.city}`);
      } else if (firstAddress.city) {
        infoLines.push(firstAddress.city);
      }
      if (firstAddress.street) {
        infoLines.push(firstAddress.street);
      }
    }
    if (contact.data.emails?.length > 0) {
      infoLines.push(contact.data.emails[0]?.email);
    }

    if (contact.data.note) {
      infoLines.push(contact.data.note);
    }
    return infoLines.join(" | ");
  };

  getContactPersonSelectOptions = (
    contact: Contact
  ): BFChooserOption<string>[] => {
    const contactPersonOptions: BFChooserOption<string>[] =
      contact.data.contactPersons?.map((contactPerson) => ({
        label: [contactPerson.firstName, contactPerson.lastName]
          .filter((e) => e)
          .join(" "),
        value: contactPerson.id,
        subLabel: contactPerson.note,
      })) || [];
    return contactPersonOptions;
  };
  getAdressSelectOptions = (contact: Contact): BFChooserOption<string>[] => {
    const addressOptions: BFChooserOption<string>[] =
      contact.data.address?.map((address) => ({
        label: [
          address.street,
          address.additional ? "/" : null,
          address.additional,
        ]
          .filter((e) => e)
          .join(" "),
        value: address.id,
        subLabel: [address.zip, address.city].filter((e) => e).join(" "),
      })) || [];
    return addressOptions;
  };
  getPhoneSelectOptions = (contact: Contact): BFChooserOption<string>[] => {
    const phones: BFChooserOption<string>[] =
      contact.data.phone?.map((phone) => ({
        label: phone.phone,
        value: phone.id,
        subLabel: phone.note,
      })) || [];
    const contactPhones =
      contact.data.contactPersons
        ?.filter((e) => e.phone)
        .map((e) => ({
          label: e.phone,
          value: e.id,
          subLabel: [
            [e.firstName, e.lastName].filter((e) => e).join(" "),
            e.note,
          ].join(" - "),
        })) || [];

    return [...phones, ...contactPhones];
  };
  getEmailSelectOptions = (contact: Contact): BFChooserOption<string>[] => {
    const emails: BFChooserOption<string>[] =
      contact.data.emails?.map((email) => ({
        label: email.email,
        value: email.id,
        subLabel: email.note,
      })) || [];
    const contactEmails =
      contact.data.contactPersons
        ?.filter((e) => e.email)
        .map((e) => ({
          label: e.email,
          value: e.id,
          subLabel: [
            [e.firstName, e.lastName].filter((e) => e).join(" "),
            e.note,
          ].join(" - "),
        })) || [];
    return [...emails, ...contactEmails];
  };
}
const ContactUtils = new ContactUtilsClass();
export default ContactUtils;
