import moment from "moment";
import { useEffect, useState } from "react";
import { PortfolioSupplyUnit } from "../../../apps/tatar/cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import {
  CBPortfolioRenter,
  CBPortfolioTechnicalUnit,
} from "../../../apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import {
  RentalAgreement,
  RentalUnit,
} from "../../../apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import { MatchQuery } from "../../../services/DataService";
import MQ from "../../../utils/MatchQueryUtils";
import StringUtils from "../../../utils/StringUtils";
import { ValidatorPopoverStyle } from "../../abstract-ui/general/ValidationPopover/ValidationPopover";
import EZAssetSelect, {
  AssetSelectProps,
} from "../../ez-form/form-elements/ez-asset-select/EZAssetSelect";
import {
  AssetSelectStyle,
  AssetValue,
} from "../../ez-form/form-elements/ez-asset-select/EZAssetSelectDropdown";
import EZAssetSelectMultiple from "../../ez-form/form-elements/ez-asset-select/EZAssetSelectMultiple";
import "./ObjectRelatedSelect.scss";

const ASSET_CONFIGS: () => {
  [key: string]: AssetSelectProps;
} = () => ({
  [AssetTypes.Rental.RentalUnit]: {
    id: AssetTypes.Rental.RentalUnit,
    label: i18n.t("ObjectRelated.RentalUnit", "Mieteinheit"),
    assetType: AssetTypes.Rental.RentalUnit,
    sort: [
      {
        key: "data.id",
        dir: "desc",
      },
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    // matchQuery: MQ.and(
    //   MQ.lt("data.moveIn", moment().startOf("d").toISOString()),
    //   MQ.or(
    //     MQ.gt("data.moveOut", moment().startOf("d").toISOString()),
    //     MQ.in("data.moveOut", [null])
    //   )
    // ),
    renderValue: (value: RentalUnit) => {
      if (!value) {
        return (
          <div className={`object-related-value rental-unit`}>
            <div className="no-data">
              {i18n.t("Global.Error.NoData", "Keine Daten gefunden.")}
            </div>
          </div>
        );
      }
      return (
        <div className={`object-related-value rental-unit`}>
          <span className={`sub`}>{value.data.id}</span>{" "}
          <span className={`main`}>{value.data.displayName}</span>
        </div>
      );
    },
    renderMenu: (value: RentalUnit) => (
      <div className={`object-related-value rental-unit`}>
        <div className={`sub`}>{value.data.id}</div>{" "}
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },

  [AssetTypes.Rental.RentalAgreement]: {
    id: AssetTypes.Rental.RentalAgreement,
    label: i18n.t("ObjectRelated.RentalAgreement", "Mietvertrag"),
    assetType: AssetTypes.Rental.RentalAgreement,
    sort: [
      {
        key: "data.id",
        dir: "desc",
      },
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    matchQuery: MQ.and(
      MQ.lt("data.moveIn", moment().startOf("d").toISOString()),
      MQ.or(
        MQ.gt("data.moveOut", moment().startOf("d").toISOString()),
        MQ.in("data.moveOut", [null])
      )
    ),
    renderValue: (value: RentalAgreement) => {
      if (!value) {
        return (
          <div className={`object-related-value rental-agreement`}>
            <div className="no-data">
              {i18n.t("Global.Error.NoData", "Keine Daten gefunden.")}
            </div>
          </div>
        );
      }
      return (
        <div className={`object-related-value rental-agreement`}>
          <span className={`sub`}>
            {value.data.id} ({StringUtils.formatDate(value.data.moveIn)} -{" "}
            {value.data.moveOut
              ? StringUtils.formatDate(value.data.moveOut)
              : i18n.t("Labels.now", "jetzt")}
            )
          </span>{" "}
          <span className={`main`}>{value.data.displayName}</span>
        </div>
      );
    },
    renderMenu: (value: RentalAgreement) => (
      <div className={`object-related-value rental-agreement`}>
        <div className={`sub`}>
          {value.data.id} ({StringUtils.formatDate(value.data.moveIn)} -{" "}
          {value.data.moveOut
            ? StringUtils.formatDate(value.data.moveOut)
            : i18n.t("Labels.now", "jetzt")}
          )
        </div>{" "}
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
  [`${AssetTypes.Rental.RentalAgreement}_old`]: {
    id: `${AssetTypes.Rental.RentalAgreement}_old`,
    label: i18n.t(
      "ObjectRelated.RentalAgreementOld",
      "Abgelaufener Mietvertrag"
    ),
    assetType: AssetTypes.Rental.RentalAgreement,
    sort: [
      {
        key: "data.id",
        dir: "desc",
      },
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    matchQuery: MQ.and(
      MQ.lt("data.moveIn", moment().startOf("d").toISOString()),
      MQ.lte("data.moveOut", moment().startOf("d").toISOString())
    ),
    renderValue: (value: RentalAgreement) => (
      <div className={`object-related-value rental-agreement`}>
        <span className={`sub`}>
          {value.data.id} ({StringUtils.formatDate(value.data.moveIn)} -{" "}
          {value.data.moveOut
            ? StringUtils.formatDate(value.data.moveOut)
            : i18n.t("Labels.now", "jetzt")}
          )
        </span>{" "}
        <span className={`main`}>{value.data.displayName}</span>
      </div>
    ),
    renderMenu: (value: RentalAgreement) => (
      <div className={`object-related-value rental-agreement`}>
        <div className={`sub`}>
          {value.data.id} ({StringUtils.formatDate(value.data.moveIn)} -{" "}
          {value.data.moveOut
            ? StringUtils.formatDate(value.data.moveOut)
            : i18n.t("Labels.now", "jetzt")}
          )
        </div>{" "}
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },

  [AssetTypes.Portfolio.Renter]: {
    id: AssetTypes.Portfolio.Renter,
    label: i18n.t("ObjectRelated.Renter", "Mieter"),
    assetType: AssetTypes.Portfolio.Renter,
    sort: [
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    matchQuery: {
      type: "and",
      query: [
        {
          name: "data.moveIn",
          op: "lt",
          type: "op",
          value: moment().startOf("d").toISOString(),
        },
        {
          type: "or",
          query: [
            {
              type: "op",
              name: "data.moveOut",
              op: "gt",
              value: moment().startOf("d").toISOString(),
            },
            {
              type: "op",
              name: "data.moveOut",
              op: "in",
              value: [null],
            },
          ],
        },
      ],
    },
    renderValue: (value: CBPortfolioRenter) => (
      <div className={`object-related-value renter`}>
        <span className={`sub`}>{value.data.renterId}</span>{" "}
        <span className={`main`}>{value.data.displayName}</span>
      </div>
    ),
    renderMenu: (value: CBPortfolioRenter) => (
      <div className={`object-related-menu renter`}>
        <div className={`sub`}>{value.data.renterId}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
  [`${AssetTypes.Portfolio.Renter}_old`]: {
    id: `${AssetTypes.Portfolio.Renter}_old`,
    label: i18n.t("ObjectRelated.OldRenter", "Ehemalige Mieter"),
    assetType: AssetTypes.Portfolio.Renter,
    sort: [
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    matchQuery: {
      type: "and",
      query: [
        {
          name: "data.moveIn",
          op: "lt",
          type: "op",
          value: moment().startOf("d").toISOString(),
        },
        {
          type: "op",
          name: "data.moveOut",
          op: "lte",
          value: moment().startOf("d").toISOString(),
        },
      ],
    },
    renderValue: (value: CBPortfolioRenter) => (
      <div className={`object-related-value renter`}>
        <span className={`sub`}>{value.data.renterId}</span>{" "}
        <span className={`main`}>{value.data.displayName}</span>
      </div>
    ),
    renderMenu: (value: CBPortfolioRenter) => (
      <div className={`object-related-menu renter`}>
        <div className={`sub`}>{value.data.renterId}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
  [AssetTypes.Portfolio.TechnicalUnit]: {
    id: AssetTypes.Portfolio.TechnicalUnit,
    label: i18n.t("ObjectRelated.TechnicalUnit", "Technische Einheit"),
    assetType: AssetTypes.Portfolio.TechnicalUnit,
    sort: [
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    matchQuery: {
      name: "data.status",
      type: "op",
      op: "eq",
      value: "active",
    },
    renderValue: (value: CBPortfolioTechnicalUnit) => (
      <div className={`object-related-value project`}>
        <span className={`sub`}>{value.data.id}</span>{" "}
        <span className={`main`}>{value.data.displayName}</span>
      </div>
    ),
    renderMenu: (value: CBPortfolioTechnicalUnit) => (
      <div className={`object-related-menu project`}>
        <div className={`sub`}>{value.data.id}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
  [AssetTypes.Portfolio.SupplyUnit]: {
    id: AssetTypes.Portfolio.SupplyUnit,
    label: i18n.t("ObjectRelated.SupplyUnit", "Versorgungseinheit"),
    assetType: AssetTypes.Portfolio.SupplyUnit,
    sort: [
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    matchQuery: {
      name: "data.status",
      type: "op",
      op: "eq",
      value: "active",
    },
    renderValue: (value: PortfolioSupplyUnit) => (
      <div className={`object-related-value project`}>
        <span className={`sub`}>{value.data.id}</span>{" "}
        <span className={`main`}>{value.data.displayName}</span>
      </div>
    ),
    renderMenu: (value: PortfolioSupplyUnit) => (
      <div className={`object-related-menu project`}>
        <div className={`sub`}>{value.data.id}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },

  [AssetTypes.Rental.RentalUnit]: {
    id: AssetTypes.Rental.RentalUnit,
    label: i18n.t("ObjectRelated.RentalUnit", "Mieteinheit (aktuell)"),
    assetType: AssetTypes.Rental.RentalUnit,
    sort: [
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    matchQuery: MQ.and(
      MQ.or(
        MQ.isNull("data.administratedTo"),
        MQ.gte("data.administratedTo", moment().endOf("day").toISOString())
      )
    ),
    renderValue: (value: RentalUnit) => (
      <div className={`object-related-value rental-unit`}>
        <span className={`sub`}>{value.data.id}</span>{" "}
        <span className={`main`}>{value.data.displayName}</span>
      </div>
    ),
    renderMenu: (value: RentalUnit) => (
      <div className={`object-related-menu rental-unit`}>
        <div className={`sub`}>{value.data.id}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
  [`${AssetTypes.Rental.RentalUnit}_historical`]: {
    id: `${AssetTypes.Rental.RentalUnit}_historical`,
    label: i18n.t(
      "ObjectRelated.RentalUnitHistroical",
      "Mieteinheit (historisch)"
    ),
    assetType: AssetTypes.Rental.RentalUnit,
    sort: [
      {
        key: "data.displayName",
        dir: "asc",
      },
    ],
    matchQuery: MQ.and(
      MQ.lt("data.administratedTo", moment().endOf("day").toISOString())
    ),
    renderValue: (value: RentalUnit) => (
      <div className={`object-related-value rental-unit`}>
        <span className={`sub`}>{value.data.id}</span>{" "}
        <span className={`main`}>{value.data.displayName}</span>
      </div>
    ),
    renderMenu: (value: RentalUnit) => (
      <div className={`object-related-menu rental-unit`}>
        <div className={`sub`}>{value.data.id}</div>
        <div className={`main`}>{value.data.displayName}</div>
      </div>
    ),
  },
});
type BaseActivitySelectProps = {
  showJump?: boolean;
  block?: boolean;
  identifier: string;
  assets?: string[];
  cleanable?: boolean;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  label?: string;
  labelPosition?: "top" | "left";
  appearance?: AssetSelectStyle;
  validatorStyle?: ValidatorPopoverStyle;
  objectId?: string;
  disabled?: boolean;
  readonly?: boolean;
  disabledAssetTypes?: string[];
};
type SingleActivitySelectProps = BaseActivitySelectProps & {
  multiple: false;
  value: AssetValue;
  onChange: (value: AssetValue) => void;
};

type MultipleActivitySelectProps = BaseActivitySelectProps & {
  multiple: true;
  value: AssetValue[];
  addButtonText?: string;
  onValueAdded?: (value: AssetValue) => void;
  onValueRemoved?: (value: AssetValue) => void;
  onChange?: (value: AssetValue[]) => void;
};
type ActviitySelectProps =
  | SingleActivitySelectProps
  | MultipleActivitySelectProps;

const ObjectRelatedSelect = (props: ActviitySelectProps) => {
  const [configs, setConfigs] = useState<AssetSelectProps[]>([]);

  useEffect(() => {
    const availableConfigs = ASSET_CONFIGS();
    setConfigs(
      (props.assets || Object.keys(availableConfigs))
        .map((asset) => availableConfigs[asset])
        .filter((e) => !!e)
    );
  }, [props.assets]);

  if (props.multiple === true) {
    return (
      <EZAssetSelectMultiple
        readonly={props.readonly}
        disabled={props.disabled}
        addButtonText={props.addButtonText}
        appearance={props.appearance}
        className="object-related-select"
        identifier={props.identifier}
        onValueAdded={props.onValueAdded}
        onValueRemoved={props.onValueRemoved}
        onChange={props.onChange}
        value={props.value}
        assetTypes={configs}
        cleanable={props.cleanable}
        label={props.label}
        labelPosition={props.labelPosition}
        validatorStyle={props.validatorStyle}
        validation={props.validation}
        disabledAssetTypes={props.disabledAssetTypes}
        block={props.block}
        showJump={props.showJump}
        additionalMatchQuery={MQ.combine("and", [
          props.objectId
            ? ({
                type: "op",
                name: "data.objectId",
                op: "in",
                value: [props.objectId],
              } as MatchQuery)
            : undefined,
        ])}
      />
    );
  } else {
    return (
      <EZAssetSelect
        readonly={props.readonly}
        disabled={props.disabled}
        appearance={props.appearance}
        className="object-related-select"
        identifier={props.identifier}
        onChange={props.onChange}
        value={props.value}
        disabledAssetTypes={props.disabledAssetTypes}
        assetTypes={configs}
        block={props.block}
        cleanable={props.cleanable}
        label={props.label}
        labelPosition={props.labelPosition}
        validatorStyle={props.validatorStyle}
        validation={props.validation}
        additionalMatchQuery={MQ.combine("and", [
          props.objectId
            ? ({
                type: "op",
                name: "data.objectId",
                op: "eq",
                value: props.objectId,
              } as MatchQuery)
            : undefined,
        ])}
      />
    );
  }
};

export default ObjectRelatedSelect;
