import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import WrappedText from "@/components/WrappedText/WrappedText";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFMessageBox from "@/modules/abstract-ui/general/Message/BFMessageBox";
import ContactUtils from "@/modules/contacts-module/ContactUtils";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { hasNoValue, hasValue } from "@/utils/Helpers";
import React from "react";
import AssetLoader from "../../../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import PersistentSplitPane from "../../../../../../../configurable/data/SplitPane/PersistentSplitPane";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { Contact } from "../../../../../../../model/db/Contact";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import { useAggregationStatisticQuery } from "../../../../../../../redux/hooks";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import GlobalActions from "../../../../../../../services/GlobalActions";
import LanguageService from "../../../../../../../services/LanguageService";
import StringUtils from "../../../../../../../utils/StringUtils";
import { RentalAgreementOptionBy } from "../../CBRentalUtils";
import {
  DepositPosition,
  EnrichtedRentalUnit,
  RentalAgreement,
  RentalUnit,
} from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import CBAgreementAutomatic from "./agreement-parts/CBAgreementAutomatic";
import CBAgreementContractBasedata from "./agreement-parts/CBAgreementContractBasedata";
import CBAgreementCopayment from "./agreement-parts/CBAgreementCopayment";
import CBAgreementDeposit from "./agreement-parts/CBAgreementDeposit";
import CBAgreementGraduatedRent from "./agreement-parts/CBAgreementGraduatedRent";
import CBAgreementIndex from "./agreement-parts/CBAgreementIndex";
import CBAgreementOption from "./agreement-parts/CBAgreementOption";
import CBAgreementPaymentInfo from "./agreement-parts/CBAgreementPaymentInfo";
import CBAgreementRentalUnits from "./agreement-parts/CBAgreementRentalUnits";
import CBAgreementRentDuration from "./agreement-parts/CBAgreementRentDuration";
import "./CBRentalAgreementDetailsPage.scss";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "./CBRentalAgreementHelper";
import CBRentalAgreementRentChange from "./CBRentalAgreementRentChange";
import { CBRentalAgreementContactOptionUpdateForm } from "./form-components/CBRentalAgreementContactForm";
import { CBRentalAgreementcopaymentUpdateForm } from "./form-components/CBRentalAgreementCopaymentForm";
import { CBRentalAgreementOptionUpdateForm } from "./form-components/CBRentalAgreementOptionForm";

interface CBRentalAgreementDetailsProps {
  goBack?: () => void;
  rentalAgreement: RentalAgreement;
  hideBackButton?: boolean;
  hideStackingPlan?: boolean;
  hideActions?: boolean;
  kind: ObjectKind;
}
export const CBRentalAgreementDetailsPage = (
  props: CBRentalAgreementDetailsProps
) => {
  const chartId = React.useRef<string>(null);
  const comp = (
    <>
      <div className={`page-content`}>
        <div className={`header`}>
          {!props.hideBackButton && (
            <BFButton
              className={`back-button`}
              appearance={"clear-on-white"}
              icon={{ size: "xs", ...DefaultIcons.BACK }}
              onClick={props.goBack}
            />
          )}
          <div className={`title`}>
            {OrgaStruct.isImportedObject(
              props.rentalAgreement.data.objectId
            ) && (
              <div className={`margin-top-10`}>
                <BFMessageBox type="info" inline small>
                  {i18n.t(
                    "cb:RentalAgreement.ImportedObject",
                    "Importierter Mietvertrag aus Domus"
                  )}
                </BFMessageBox>
              </div>
            )}
            <div className={`id`}>
              {i18n.t("cb:RentalAgreement.DetailsTitle", "Mietvertrag")}{" "}
              {props.rentalAgreement.data.id}
              <DebugDataComponent data={props.rentalAgreement} />
            </div>
            <div className={`name`}>
              {props.rentalAgreement.data.displayName}
            </div>
            <div className={`object`}>
              <BFDetailsButton
                appearance="link"
                size="xs"
                data={{
                  assetType: AssetTypes.Portfolio.Object,
                  assetId: props.rentalAgreement.data.objectId,
                  type: props.rentalAgreement.data.type,
                }}
              >
                {OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.id}{" "}
                -{" "}
                {
                  OrgaStruct.getObject(props.rentalAgreement.data.objectId)
                    ?.displayName
                }
              </BFDetailsButton>
            </div>
          </div>
        </div>
        <div className={`content`}>
          <CBAgreementRentDuration
            marks={props.rentalAgreement.data.marks?.rentDuration}
            rentalAgreement={props.rentalAgreement}
            // editable //todo make editable
            data={{
              moveIn: props.rentalAgreement.data.moveIn,
              agreementExpiration:
                props.rentalAgreement.data.agreementExpiration,
              moveOut: props.rentalAgreement.data.moveOut,
            }}
          />
          <CBAgreementContractBasedata
            rentalAgreement={props.rentalAgreement}
            // editable //todo make editable
            data={{
              contractType: props.rentalAgreement.data.contractType,
              displayName: props.rentalAgreement.data.displayName,
              id: props.rentalAgreement.data.id,
              note: props.rentalAgreement.data.note,
            }}
          />
          <CBAgreementPaymentInfo
            data={{
              paymentPositions: props.rentalAgreement.data.paymentPositions,
              rentPaymentTimingOptions:
                props.rentalAgreement.data.rentPaymentTimingOptions,
              taxable: props.rentalAgreement.data.taxable
                ? "taxable"
                : "notTaxable",
            }}
            kind={props.kind}
            marks={props.rentalAgreement.data.marks?.paymentInfo}
            objectId={props.rentalAgreement.data.objectId}
            editable
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementDeposit
            editable
            data={{
              deposits: props.rentalAgreement.data.deposit,
            }}
            marks={props.rentalAgreement.data.marks?.graduatedRent}
            objectId={props.rentalAgreement.data.objectId}
            rentalAgreement={props.rentalAgreement}
          />

          <CBAgreementGraduatedRent
            kind={props.kind}
            marks={props.rentalAgreement.data.marks?.graduatedRent}
            objectId={props.rentalAgreement.data.objectId}
            taxable={props.rentalAgreement.data.taxable}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementRentalUnits
            editable
            marks={props.rentalAgreement.data.marks?.rentalUnits}
            data={{
              rentalUnits: props.rentalAgreement.data.rentalUnits,
            }}
            rentalAgreement={props.rentalAgreement}
            kind={props.kind}
            objectId={props.rentalAgreement.data.objectId}
            hideStackingplan
          />
          <CBAgreementIndex
            editable
            marks={props.rentalAgreement.data.marks?.index}
            data={{
              index: props.rentalAgreement.data.index,
            }}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementOption
            editable
            marks={props.rentalAgreement.data.marks?.option}
            data={{
              option: props.rentalAgreement.data.option,
            }}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementAutomatic
            editable
            data={{
              automatic: props.rentalAgreement.data.automatic,
            }}
            marks={props.rentalAgreement.data.marks?.automatic}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementCopayment
            editable
            data={{
              copayment: props.rentalAgreement.data.copayment,
            }}
            marks={props.rentalAgreement.data.marks?.copayment}
            rentalAgreement={props.rentalAgreement}
          />
        </div>
      </div>
    </>
  );
  return (
    <div className="cb-rental-agreement-details">
      {props.hideStackingPlan ? (
        comp
      ) : (
        <PersistentSplitPane
          identifier="cb-rental-agreement-details"
          split="horizontal"
          defaultSize={"50%"}
          maxSize={-200}
          minSize={150}
          primary="first"
          allowResize
          onSizeChange={() => {
            if (chartId.current) {
              DataBusDefaults.chartResized(chartId.current);
            }
          }}
        >
          {props.hideStackingPlan === true ? null : (
            <div className={`object-stacking-plan-wrapper`}>
              <ObjectStackingPlan
                onMounted={(ident, chart) => {
                  chartId.current = ident;
                }}
                objectId={props.rentalAgreement.data.objectId}
                markedUnits={props.rentalAgreement.data.rentalUnits}
                selectMode="single"
                onSelect={(selected: EnrichtedRentalUnit) => {
                  GlobalActions.openDetails(
                    selected.data.rentalStatus === "vacant"
                      ? AssetTypes.Rental.RentalUnit
                      : AssetTypes.Rental.RentalAgreement,
                    selected.data.rentalStatus === "vacant"
                      ? selected._id
                      : selected.data.agreement._id,
                    selected.data.type
                  );
                  //   DataBusDefaults.route({
                  //     route: `/rental-unit/${selected}`,
                  //     stayInApp: true,
                  //   });
                }}
              />
            </div>
          )}
          {comp}
        </PersistentSplitPane>
      )}
    </div>
  );
};

const RentalUnits = (props: { rentalAgreement: RentalAgreement }) => {
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.Rentalunits", "Mieteinheiten")}
    >
      <>
        <RentalUnitTableHeader />
        {props.rentalAgreement.data.rentalUnits.map((rentalUnit) => (
          <AssetLoader
            assetType={AssetTypes.Rental.RentalUnit}
            id={rentalUnit}
            render={(rentalUnit: RentalUnit) => (
              <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
            )}
          />
        ))}
      </>
    </RentalAgreementSection>
  );
};

const DepositView = (props: {
  deposit: DepositPosition[];
  objectId: string;
}) => {
  const deposit = props.deposit || [];

  return (
    <AssetLoader
      assetType={AssetTypes.Portfolio.Object}
      id={props.objectId}
      render={(object: OAObject) => {
        const depositPositions = object.data.feature.immo?.accounting.deposit;
        return (
          <RentalAgreementSection
            title={i18n.t("cb:RentalAgreement.Labels.Deposit", "Kaution")}
          >
            <div>
              {" "}
              {deposit.length === 0 && (
                <div className={`__empty`}>
                  {i18n.t(
                    "cb:RentalAgreement.Labels.NoDeposit",
                    "Keine Kaution hinterlegt"
                  )}
                </div>
              )}
              {deposit.map((deposit) => (
                <div className={`__flex-wrap`}>
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.DepositValue",
                      "Kautionswert"
                    )}
                    value={deposit.depositValue}
                    formatter={(value) =>
                      StringUtils.formatCurrency(value, true)
                    }
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.DepositType",
                      "Kautionsart"
                    )}
                    value={
                      depositPositions?.find((e) => e.id === deposit.identifier)
                        ?.displayName
                    }
                    formatter={LanguageService.translateLabel}
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.DepositStart",
                      "Kautionsbeginn"
                    )}
                    value={deposit.depositStart}
                    formatter={StringUtils.formatDate}
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.DepositStart",
                      "Kautionsbeginn"
                    )}
                    value={deposit.depositStart}
                    formatter={StringUtils.formatDate}
                  />
                </div>
              ))}
            </div>
          </RentalAgreementSection>
        );
      }}
    />
  );
};

const RentEntry = (props: {
  rentalAgreement: RentalAgreement;
  hideActions?: boolean;
  kind: ObjectKind;
}) => {
  const plannedRent = useAggregationStatisticQuery(
    AssetTypes.Rental.RentalUnit,
    {
      type: "and",
      query: [
        {
          type: "op",
          name: "_id",
          op: "in",
          value: props.rentalAgreement.data.rentalUnits,
        },
      ],
    },
    [
      {
        name: "general",
        op: [
          {
            key: "rentNet",
            op: "sum",
            field: "data.rentNet",
          },
          {
            key: "rentGross",
            op: "sum",
            field: "data.rentGross",
          },
          {
            key: "operatingCostNet",
            op: "sum",
            field: "data.operatingCostNet",
          },
          {
            key: "operatingCostGross",
            op: "sum",
            field: "data.operatingCostGross",
          },
        ],
        query: {},
      },
    ]
  );
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.Rent", "Miete")}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              ModalManager.show({
                backdrop: "static",
                noPadding: true,
                size: "lg",
                content: (states, setStates, closeModal) => (
                  <CBRentalAgreementRentChange
                    onClose={closeModal}
                    kind={props.kind}
                    rentalAgreementId={props.rentalAgreement._id}
                  />
                ),
              });
            }
      }
    >
      <div className={`__flex-wrap`}>
        <BFValueDisplay
          label={i18n.t("cb:RentalAgreement.Labels.rentNet", "Miete netto")}
          value={props.rentalAgreement?.data?.rentNet}
          formatter={(value) => StringUtils.formatCurrency(value, true)}
        />
        {props.rentalAgreement.data.taxable && (
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Labels.rentGross",
              "Miete brutto"
            )}
            value={
              <>
                <span className={`should`}>
                  {StringUtils.formatCurrency(
                    props.rentalAgreement.data.rentGross,
                    true
                  )}
                </span>{" "}
                {plannedRent.data ? (
                  <span className={`plan`}>
                    ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                    {StringUtils.formatCurrency(
                      plannedRent.data["general"]?.rentGross,
                      true
                    )}
                    )
                  </span>
                ) : null}
              </>
            }
          />
        )}
        <BFValueDisplay
          label={i18n.t(
            "cb:RentalAgreement.Labels.operatingCostNet",
            "Nebenkosten netto"
          )}
          value={
            <>
              <span className={`should`}>
                {StringUtils.formatCurrency(
                  props.rentalAgreement.data.operatingCostNet,
                  true
                )}
              </span>{" "}
              {plannedRent.data ? (
                <span className={`plan`}>
                  ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                  {StringUtils.formatCurrency(
                    plannedRent.data["general"]?.operatingCostNet,
                    true
                  )}
                  )
                </span>
              ) : null}
            </>
          }
        />
        {props.rentalAgreement.data.taxable && (
          <BFValueDisplay
            label={i18n.t("cb:RentalAgreement.Labels.rentNet", "Miete netto")}
            value={
              <>
                <span className={`should`}>
                  {StringUtils.formatCurrency(
                    props.rentalAgreement.data.operatingCostGross,
                    true
                  )}
                </span>{" "}
                {plannedRent.data ? (
                  <span className={`plan`}>
                    ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                    {StringUtils.formatCurrency(
                      plannedRent.data["general"]?.operatingCostGross,
                      true
                    )}
                    )
                  </span>
                ) : null}
              </>
            }
          />
        )}
      </div>
    </RentalAgreementSection>
  );
};

const OptionData = (props: { rentalAgreement: RentalAgreement }) => {
  const option = props.rentalAgreement.data.option;
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.OptionData", "Option")}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              ModalManager.show({
                size: "sm",
                noPadding: true,
                content: (state, setState, close) => {
                  return (
                    <CBRentalAgreementOptionUpdateForm
                      rentalAgreement={props.rentalAgreement}
                      onClose={close}
                    />
                  );
                },
              });
            }
      }
    >
      <>
        {hasNoValue(option) && (
          <div className={`__empty`}>
            {i18n.t(
              "cb:RentalAgreement.Labels.NoOption",
              "Für den Vertrag wurde keine Option hinterlegt"
            )}
          </div>
        )}
        {hasValue(option) && (
          <>
            {option.map((option, index) => (
              <div className={`option-entry`}>
                <div className={`title`}>
                  {i18n.t("cb:RentalAgreement.Labels.Option.option", "Option")}{" "}
                  {index + 1}
                </div>

                <div className={`__flex-wrap padding-bottom-10`}>
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.until",
                      "Option bis"
                    )}
                    value={option.optionUntil}
                    formatter={StringUtils.formatDate}
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.optionBy",
                      "Option von"
                    )}
                    value={option.optionBy}
                    formatter={(value) =>
                      RentalAgreementOptionBy().find((e) => e.value === value)
                        ?.label
                    }
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.extendsByMonths",
                      "Verlängerung um"
                    )}
                    value={option.extendsByMonths}
                    formatter={(value) =>
                      `${value} ${i18n.t("Global.Labels.months", "Monate")}`
                    }
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.optionsUsed",
                      "Option verwendet"
                    )}
                    value={StringUtils.formatBoolean(option.optionUsed)}
                  />
                </div>

                <div className={`__flex`}>
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.note",
                      "Notiz"
                    )}
                    value={option.note}
                    formatter={(value) => <WrappedText text={value} />}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </>
    </RentalAgreementSection>
  );
};

const CopaymentData = (props: { rentalAgreement: RentalAgreement }) => {
  const copayment = props.rentalAgreement.data.copayment;
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.Copayment", "Eigenanteil")}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              ModalManager.show({
                size: "sm",
                noPadding: true,
                content: (state, setState, close) => {
                  return (
                    <CBRentalAgreementcopaymentUpdateForm
                      rentalAgreement={props.rentalAgreement}
                      onClose={close}
                    />
                  );
                },
              });
            }
      }
    >
      <>
        {hasNoValue(copayment) && (
          <div className={`__empty`}>
            {i18n.t(
              "cb:RentalAgreement.Labels.NoCopayment",
              "Für den Vertrag wurde kein Eigenanteil hinterlegt"
            )}
          </div>
        )}
        {hasValue(copayment) && (
          <>
            <div className={`__flex-wrap padding-bottom-5`}>
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.valueByCase",
                  "Eigenanteil je Fall"
                )}
                value={copayment.valueByCase}
                formatter={(value) => StringUtils.formatCurrency(value)}
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.maxByYear",
                  "Maximaler Eigenanteil pro Jahr"
                )}
                value={copayment.maxByYear}
                formatter={(value) => StringUtils.formatCurrency(value)}
              />
            </div>
            <div className={`__flex-wrap padding-bottom-10`}>
              <BFValueDisplay
                label={i18n.t("cb:RentalAgreement.Form.Fields.note", "Notiz")}
                value={copayment.note}
                formatter={(value) => <WrappedText text={value} />}
              />
            </div>
          </>
        )}
      </>
    </RentalAgreementSection>
  );
};

const ContactData = (props: { rentalAgreement: RentalAgreement }) => {
  const tenant = props.rentalAgreement.data.tenant;
  const contactOption = props.rentalAgreement.data.contactOption;
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.Tenant", "Mieter")}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              ModalManager.show({
                size: "sm",
                noPadding: true,
                content: (state, setState, close) => {
                  return (
                    <CBRentalAgreementContactOptionUpdateForm
                      rentalAgreement={props.rentalAgreement}
                      onClose={close}
                    />
                  );
                },
              });
            }
      }
    >
      <>
        <AssetLoader
          assetType={AssetTypes.Contact}
          id={props.rentalAgreement.data.tenant}
          render={(tenant: Contact) => (
            <div className={`__flex-wrap padding-bottom-10`}>
              <BFValueDisplay
                label={i18n.t("cb:Tenant.Labels.Name", "Mieter")}
                value={
                  <BFDetailsButton
                    appearance="link"
                    noPadding
                    data={{
                      assetType: AssetTypes.Contact,
                      assetId: tenant._id,
                      type: tenant.data.type,
                      params: {
                        contactType: "TENANT",
                      },
                    }}
                  >
                    {tenant.data.displayName}
                  </BFDetailsButton>
                }
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.addressId",
                  "Kontaktadresse"
                )}
                value={contactOption?.addressId}
                formatter={(value) =>
                  ContactUtils.getAdressSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.contactPersonId",
                  "Kontaktperson"
                )}
                value={contactOption?.contactPersonId}
                formatter={(value) =>
                  ContactUtils.getContactPersonSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.mail",
                  "Kontakt E-Mail"
                )}
                value={contactOption?.mail}
                formatter={(value) =>
                  ContactUtils.getEmailSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.phone",
                  "Kontakt Telefon"
                )}
                value={contactOption?.phone}
                formatter={(value) =>
                  ContactUtils.getPhoneSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.sms",
                  "Kontakt SMS"
                )}
                value={contactOption?.sms}
                formatter={(value) =>
                  ContactUtils.getPhoneSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />
            </div>
          )}
        />
      </>
    </RentalAgreementSection>
  );
};

const RentalAgreementSection = (props: {
  title: string;
  onEdit?: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className={`rental-agreement-section`}>
      <div className={`section-title`}>
        <div className={`__h3`}>{props.title}</div>
        {props.onEdit && (
          <BFButton
            noPadding
            appearance="link"
            onClick={props.onEdit}
            size="sm"
          >
            {i18n.t("Global.Buttons.Customize", "Anpassen")}
          </BFButton>
        )}
      </div>
      <div className={`section-content`}>{props.children}</div>
    </div>
  );
};
