import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import StructLoader from "@/components/StructLoader/StructLoader";
import BFTabContainer from "@/modules/abstract-ui/data/tabs/BFTabContainer";
import DSImageGallery from "@/modules/document-store/components/DSGallery/DSImageGallery";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import moment from "moment";
import React from "react";
import AssetLoader from "../../../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import ObjectDetailView from "../../../../../../../components/ObjectDetailView/ObjectDetailView";
import PersistentSplitPane from "../../../../../../../configurable/data/SplitPane/PersistentSplitPane";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { Contact } from "../../../../../../../model/db/Contact";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import { DocumentStoreDocument } from "../../../../../../../modules/document-store/DSInterfaces";
import DocumentStoreRouted from "../../../../../../../modules/document-store/DocumentStoreRouted";
import { RSLinkedAssetFieldIdentifier } from "../../../../../../../modules/resubmission/RSConstants";
import { RSAssetBaseParams } from "../../../../../../../modules/resubmission/RSInterfaces";
import useOpenResubmissionForm from "../../../../../../../modules/resubmission/hooks/useOpenResubmissionForm";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import GlobalActions from "../../../../../../../services/GlobalActions";
import LanguageService from "../../../../../../../services/LanguageService";
import PermissionService from "../../../../../../../services/PermissionService";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import { CB_RENTALUNIT_DOCUMENTS_FIELD_PATH } from "../../../../CashBudgetUtils";
import {
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import {
  EnrichtedRentalUnit,
  RentalAgreement,
  RentalUnit,
} from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import "./CBRentalUnitDetails.scss";
import CBRentalUnitForm from "./CBRentalUnitForm";
import CBRentalUnitPlanDataForm from "./CBRentalUnitPlanDataForm";
import CBRentalUnitResubmissionView from "./resubmission/CBRentalUnitResubmissionView";

interface CBRentalUnitDetailsProps {
  goBack: () => void;
  rentalUnit: RentalUnit;
  kind: ObjectKind;
  integrated?: boolean;
}
const CBRentalUnitDetails = (props: CBRentalUnitDetailsProps) => {
  const { openResubmissionBtnIconProps, openResubmissionDialog } =
    useOpenResubmissionForm();

  if (!props.rentalUnit) {
    return null;
  }
  return (
    <StructLoader
      unitType={props.rentalUnit.data.type}
      structTypes={["unit", "orga", "objectKind"]}
      render={() => (
        <ObjectDetailView
          integrated={props.integrated}
          goBack={props.goBack}
          className="cb-rental-unit-detail-view"
          mainTitle={i18n.t("cb:RentalUnit.Title", "Mieteinheit")}
          main={(isMobile) => (
            <DetailsPage hideBackButton={isMobile} {...props} />
          )}
          routes={[
            // {
            //   name: "Dashboard",
            //   route: "dashboard",
            //   render: () => <div>Dashboard</div>,
            // },
            // {
            //   name: i18n.t("cb:RentalUnit.Tabs.History", "Verlauf"),
            //   route: "history",
            //   render: () => <div>test</div>,
            // },
            {
              name: i18n.t("cb:RentalUnit.Tabs.Documents", "Dokumente"),
              route: "attachments",
              render: () => (
                <div>
                  <DocumentStoreRouted
                    assetParams={{
                      asset: props.rentalUnit,
                      assetType: AssetTypes.Rental.RentalUnit,
                      type: props.rentalUnit.data.type,
                      documentsFieldPath: "data.attachments",
                    }}
                    documentEntryActions={[
                      {
                        type: "button",
                        text: i18n.t(
                          "Resubmission.Labels.Create",
                          "Wiedervorlage erstellen"
                        ),
                        onSelect: (document: DocumentStoreDocument) => {
                          const type = props.rentalUnit.data.type;

                          const assetParams: RSAssetBaseParams = {
                            assetType: AssetTypes.Rental.RentalUnit,
                            assetId: props.rentalUnit._id,
                            assetDescription: `${i18n.t(
                              "AssetTypes.CB.Labels.RentalUnit",
                              "Mieteinheit"
                            )} - ${i18n.t(
                              "DocumentStore.Labels.Document",
                              "Dokument:"
                            )} ${document.name}`,
                            assetField: {
                              identifier:
                                RSLinkedAssetFieldIdentifier.DSDocument,
                              fieldPath: CB_RENTALUNIT_DOCUMENTS_FIELD_PATH,
                              id: document.linkToCdn,
                            },
                          };

                          openResubmissionDialog({
                            linkedAsset: assetParams,
                            typePermissions: [type],
                          });
                        },
                        icon: openResubmissionBtnIconProps,
                      },
                    ]}
                  />
                </div>
              ),
            },
            {
              name: i18n.t("cb:RentalUnit.Tabs.Resubmission", "Wiedervorlagen"),
              route: "resubmissions",
              render: () => (
                <CBRentalUnitResubmissionView rentalUnit={props.rentalUnit} />
              ),
            },
          ]}
        />
      )}
    />
  );
};

export default CBRentalUnitDetails;

const DetailsPage = (
  props: CBRentalUnitDetailsProps & { hideBackButton: boolean }
) => {
  const rentalStatus = getConfigRentalStatus(
    props.rentalUnit.data.rentalStatus
  );
  const chartId = React.useRef<string>(null);
  const unit = ObjectKindStruct.getUnitTypeBy(props.rentalUnit.data.unitType);
  const unitTypeGroup = getConfigRentalUnitTypeGroup(unit?.group);

  const obj = OrgaStruct.getObject(props.rentalUnit.data.objectId);
  const kind = ObjectKindStruct.getKind(obj?.objectKindId);
  const editable = true;
  return (
    <div className="main-page __card">
      <PersistentSplitPane
        identifier="cb-rental-unit-details"
        split="horizontal"
        defaultSize={"50%"}
        maxSize={-200}
        minSize={150}
        primary="first"
        allowResize
        onSizeChange={() => {
          if (chartId.current) {
            DataBusDefaults.chartResized(chartId.current);
          }
        }}
      >
        <div className={`top-content-wrapper`}>
          <BFTabContainer
            defaultActiveKey="stackingplan"
            tabs={[
              {
                id: "stackingplan",
                label: i18n.t("cb:StackingPlan", "Stackingplan"),
                content: () => (
                  <ObjectStackingPlan
                    onMounted={(ident, chart) => {
                      chartId.current = ident;
                    }}
                    objectId={props.rentalUnit.data.objectId}
                    markedUnits={[props.rentalUnit._id]}
                    selectMode="single"
                    onSelect={(selected: EnrichtedRentalUnit) => {
                      GlobalActions.openDetails(
                        selected.data.rentalStatus === "vacant"
                          ? AssetTypes.Rental.RentalUnit
                          : AssetTypes.Rental.RentalAgreement,
                        selected.data.rentalStatus === "vacant"
                          ? selected._id
                          : selected.data.agreement._id,
                        selected.data.type
                      );
                    }}
                  />
                ),
              },
              {
                id: "Gallery",
                label: i18n.t("cb:Gallery", "Bilder"),
                content: () => (
                  <DSImageGallery
                    directory={
                      (
                        props.kind.data.features?.find(
                          (e) => e.type === "immo"
                        ) as ObjectKindFeature_IMMO
                      )?.rentalUnitPhotoDirectory
                    }
                    assetParams={{
                      asset: props.rentalUnit,
                      assetType: AssetTypes.Rental.RentalUnit,
                      documentsFieldPath: "data.attachments",
                      type: props.rentalUnit.data.type,
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
        <div className={`page-content`}>
          <div className={`header`}>
            {!props.hideBackButton && (
              <BFButton
                className={`back-button`}
                appearance={"clear-on-white"}
                icon={{ size: "xs", ...DefaultIcons.BACK }}
                onClick={props.goBack}
              />
            )}
            <div className={`title`}>
              <div className={`id`}>
                <DebugDataComponent data={props.rentalUnit} />
                {i18n.t("cb:RentalUnit.DetailsTitle", "Mieteinheit")}{" "}
                {props.rentalUnit.data.id}
              </div>
              <div className={`name`}>{props.rentalUnit.data.displayName}</div>
              <div className={`object`}>
                <BFDetailsButton
                  appearance="link"
                  size="xs"
                  data={{
                    assetType: AssetTypes.Portfolio.Object,
                    assetId: props.rentalUnit.data.objectId,
                    type: props.rentalUnit.data.type,
                  }}
                >
                  {OrgaStruct.getObject(props.rentalUnit.data.objectId)?.id} -{" "}
                  {
                    OrgaStruct.getObject(props.rentalUnit.data.objectId)
                      ?.displayName
                  }
                </BFDetailsButton>
              </div>
            </div>
            {
              // PermissionService.hasBusinessUnitRole("cb_rentalEdit") &&
              editable && (
                <div>
                  <BFButton
                    appearance="link"
                    tooltip={{
                      tooltip: i18n.t("Global.Buttons.edit", "Bearbeiten"),
                    }}
                    icon={{ ...DefaultIcons.EDIT, size: "xs" }}
                    onClick={() => {
                      ModalManager.show({
                        backdrop: "static",
                        size: "lg",
                        noPadding: true,
                        content: (state, setState, onClose) => (
                          <CBRentalUnitForm
                            rentalUnit={props.rentalUnit}
                            kind={kind}
                            onClose={onClose}
                          />
                        ),
                      });
                    }}
                  />
                </div>
              )
            }
          </div>
          <div className={`content`}>
            <div className={`unit-data entry-list`}>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalUnit.Labels.RentalStatus", "Status")}
                </div>
                <div className={`value`}>
                  {rentalStatus ? (
                    <BFStatus
                      size="sm"
                      color={rentalStatus.color}
                      label={rentalStatus.label}
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>

              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalUnit.Labels.unitTypeGroup", "Nutzart")}
                </div>
                <div className={`value`}>
                  {unitTypeGroup ? (
                    <BFStatus
                      size="sm"
                      color={unitTypeGroup.color}
                      label={unitTypeGroup.label}
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </div>

              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalUnit.Labels.unit", "Art")}
                </div>
                <div className={`value`}>
                  {unit
                    ? LanguageService.translateLabel(unit.displayName)
                    : "-"}
                </div>
              </div>

              <div className={`entry`}>
                <div className={`label`}>
                  {unitTypeGroup.areaType === "area"
                    ? i18n.t("cb:RentalUnit.Labels.Area", "Fläche")
                    : i18n.t("cb:RentalUnit.Labels.Count", "Anzahl")}
                </div>
                <div className={`value`}>
                  {unitTypeGroup.areaType === "area"
                    ? `${StringUtils.formatNumber(
                        props.rentalUnit.data.area
                      )} ${StringUtils.getAreaUnit()}`
                    : props.rentalUnit.data.quantity}
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalUnit.Labels.Floor", "Etage/Ebene")}
                </div>
                <div className={`value`}>{props.rentalUnit.data.floor}</div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalUnit.Labels.Building", "Gebäude")}
                </div>
                <div className={`value`}>
                  {props.rentalUnit.data.building || "-"}
                </div>
              </div>
            </div>
            <div className={`sub-head`}>
              <div className={`__h3`}>
                {i18n.t("cb:RentalUnit.Labels.PlanRent", "Planmiete")}
              </div>
              {PermissionService.hasObjectKindPermission(
                kind,
                "rental.units.createEdit"
              ) && (
                <BFButton
                  appearance="link"
                  onClick={() => {
                    ModalManager.show({
                      backdrop: "static",
                      size: "xs",
                      noPadding: true,
                      content: (state, setState, onClose) => (
                        <CBRentalUnitPlanDataForm
                          rentalUnit={props.rentalUnit}
                          onClose={onClose}
                        />
                      ),
                    });
                  }}
                  noPadding
                >
                  {i18n.t("Global.Buttons.edit")}
                </BFButton>
              )}
            </div>
            <div className={`plan  entry-list`}>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalUnit.Labels.RentGross", "Miete Brutto")}
                </div>
                <div className={`value`}>
                  {StringUtils.formatCurrency(props.rentalUnit.data.rentGross)}
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalUnit.Labels.RentNet", "Miete Netto")}
                </div>
                <div className={`value`}>
                  {StringUtils.formatCurrency(props.rentalUnit.data.rentNet)}
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t(
                    "cb:RentalUnit.Labels.OperatingCostGross",
                    "Nebenkosten Brutto"
                  )}
                </div>
                <div className={`value`}>
                  {StringUtils.formatCurrency(
                    props.rentalUnit.data.operatingCostGross
                  )}
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t(
                    "cb:RentalUnit.Labels.OperatingCostNet",
                    "Nebenkosten Netto"
                  )}
                </div>
                <div className={`value`}>
                  {StringUtils.formatCurrency(
                    props.rentalUnit.data.operatingCostNet
                  )}
                </div>
              </div>
            </div>

            <div className={`__h3`}>
              {i18n.t(
                "cb:RentalUnit.Labels.CurrentAgreement",
                "Aktueller Mieter"
              )}
            </div>
            {props.rentalUnit && (
              <AssetLoader
                ignoreDelay
                assetType={AssetTypes.Rental.RentalAgreement}
                query={MQ.combine("and", [
                  {
                    type: "op",
                    op: "eq",
                    name: "data.rentalUnits",
                    value: props.rentalUnit._id,
                  },
                  MQ.combine("or", [
                    {
                      type: "op",
                      op: "gt",
                      name: "data.moveOut",
                      value: moment().startOf("d").toISOString(),
                    },
                    {
                      type: "op",
                      op: "in",
                      name: "data.moveOut",
                      value: [null, ""],
                    },
                  ]),
                  {
                    type: "op",
                    op: "lt",
                    name: "data.moveIn",
                    value: moment().startOf("d").toISOString(),
                  },
                ])}
                render={(agreement) => {
                  if (!agreement) {
                    return (
                      <div className={`__empty`}>
                        {i18n.t(
                          "cb:RentalUnit.Labels.NoAgreement",
                          "Kein aktueller Mietvertrag vorhanden"
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <RentalAgreementDetails rentalAgreement={agreement} />
                    );
                  }
                }}
              />
            )}

            <div className={`__h3`}>
              {i18n.t(
                "cb:RentalUnit.Labels.FutureAgreement",
                "Zukünftiger Mieter"
              )}
            </div>
            {props.rentalUnit && (
              <AssetLoader
                ignoreDelay
                assetType={AssetTypes.Rental.RentalAgreement}
                query={MQ.combine("and", [
                  {
                    type: "op",
                    op: "eq",
                    name: "data.rentalUnits",
                    value: props.rentalUnit._id,
                  },
                  {
                    type: "op",
                    op: "gt",
                    name: "data.moveIn",
                    value: moment().startOf("d").toISOString(),
                  },
                ])}
                render={(agreement) => {
                  if (!agreement) {
                    return (
                      <div className={`__empty`}>
                        {i18n.t(
                          "cb:RentalUnit.Labels.NoAgreementFuture",
                          "Kein zukünftiger Mietvertrag vorhanden"
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <RentalAgreementDetails rentalAgreement={agreement} />
                    );
                  }
                }}
              />
            )}
          </div>
        </div>
      </PersistentSplitPane>
    </div>
  );
};

const RentalAgreementDetails = (props: {
  rentalAgreement: RentalAgreement;
}) => {
  return (
    <div className={`rental-agreement`}>
      <div className={`entry-list`}>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:RentalAgreement.Labels.RentalAgreement", "Mietvertrag")}
          </div>
          <div className={`value`}>
            <BFDetailsButton
              noPadding
              appearance="link"
              data={{
                assetType: AssetTypes.Rental.RentalAgreement,
                assetId: props.rentalAgreement._id,
                type: props.rentalAgreement.data.type,
              }}
            >
              {props.rentalAgreement.data.displayName}
            </BFDetailsButton>
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:RentalAgreement.Labels.Renter", "Mieter")}
          </div>
          <div className={`value`}>
            <AssetLoader
              assetType={AssetTypes.Contact}
              id={props.rentalAgreement.data.tenant}
              render={(tenant: Contact) => (
                <BFButton
                  noPadding
                  appearance="link"
                  onClick={() => {
                    GlobalActions.openDetails(
                      AssetTypes.Contact,
                      tenant._id,
                      tenant.data.type,
                      {
                        contactType: "TENANT",
                      }
                    );
                  }}
                >
                  {tenant.data.displayName}
                </BFButton>
              )}
            />
          </div>
        </div>
      </div>
      <div className={`entry-list`}>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:RentalAgreement.Labels.moveIn", "Mietbeginn")}
          </div>
          <div className={`value`}>
            {props.rentalAgreement.data.moveIn
              ? moment(props.rentalAgreement.data.moveIn).format(
                  i18n.t("Formats.dateFormat")
                )
              : "-"}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Fields.agreementExpiration",
              "Vertrag bis"
            )}
          </div>
          <div className={`value`}>
            {props.rentalAgreement.data.agreementExpiration
              ? moment(props.rentalAgreement.data.agreementExpiration).format(
                  i18n.t("Formats.dateFormat")
                )
              : "-"}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:RentalAgreement.Form.Fields.moveOut", "Auszug")}
          </div>
          <div className={`value`}>
            {props.rentalAgreement.data.moveOut
              ? moment(props.rentalAgreement.data.moveOut).format(
                  i18n.t("Formats.dateFormat")
                )
              : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};
