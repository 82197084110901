import CDNPDFViewer from "@/components/PDFViewer/CDNPDFViewer";
import { PDFDocumentRect } from "@/components/PDFViewer/PDFViewerHooks";
import BaseAsset from "@/model/general-assets/BaseAsset";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import "./BFPDFOverlay.scss";

interface BFPDFOverlayProps {
  asset: BaseAsset;
  value: PDFDocumentRect[];
  assetField: string;
  assetType: string;
}
const BFPDFOverlay = (props: BFPDFOverlayProps) => {
  const [index, setIndex] = useState(0);
  const cdnIds = _.uniq(props.value.map((rect) => rect.cdnID));
  const cdnObj = cdnIds.map((e) => props.asset.cdn?.find((a) => a.id === e));

  if (cdnIds.length > 0) {
    return (
      <div className={classNames(`bf-pdf-overlay`)}>
        <CDNPDFViewer
          asset={props.asset}
          assetField={props.assetField}
          assetType={props.assetType}
          cdnID={cdnIds[index]}
          filename={cdnObj[index]?.filename}
          highlights={props.value
            .filter((e) => e.cdnID === cdnIds[index])
            .map((rect, index) => ({
              ...rect,
              value: rect.id,
              identifier: rect.id,
              scrollIntoView: index === 0,
            }))}
        />
      </div>
    );
  } else {
    return <div>no pdfs</div>;
  }
};

export default BFPDFOverlay;
