import { LexoRank } from "lexorank";
import moment from "moment";
import { useState } from "react";
import Truncate from "react-truncate";
import Userlabel from "../../../../components/AvatarComponent/Userlabel";
import ConfigurableContextContainerComponent from "../../../../configurable/components/ContextComponent/ConfigurableContextContainerComponent";
import DebugDataComponent from "../../../../debug/DebugDataComponent";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import { AssetCell } from "../../../../modules/abstract-ui/data/table/TableUtils";
import BFDetailsButton from "../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import BFOverlay from "../../../../modules/abstract-ui/general/whisper/BFOverlay";
import ObjectRelatedUtils from "../../../../modules/structure-components/object/ObjectRelatedUtils";
import LanguageService from "../../../../services/LanguageService";
import StringUtils from "../../../../utils/StringUtils";
import CashBudgetUtils from "../CashBudgetUtils";
import { CashBudgetBookingAssignmentRule } from "../model/CashBudgetCofiguration";
import {
  AssetCashBudgetEntry,
  CashBudgetEntryHistory,
} from "../model/CashBudgetEntry";
import CashBudgetAttachments from "./CashBudgetAttachments";
import { DetailEntryType } from "./CashBudgetCategoryDetailEntryTypes";
import "./CashBudgetDetailEntryCardContent.scss";
import CashBudgetLinks from "./CashBudgetLinks";

const getTopHeader = (entry) => {
  if (!entry.data.type) {
    return null;
  }
  if (entry.data.type === DetailEntryType.INVOICE) {
    return i18n.t("cb:CBEntryType.Invoice", "Offene Zahlung (Rechnung)");
  }
  if (entry.data.type === DetailEntryType.COMPARISON_FIX) {
    return i18n.t("cb:CBEntryType.ComparisonFix", "Fixer SOLL-Wert");
  }
  if (entry.data.type === DetailEntryType.COMPARISON_ADJUSTMENTS) {
    return i18n.t(
      "cb:CBEntryType.ComparisonAdjustments",
      "Angepasster SOLL-Wert"
    );
  }
  if (entry.data.type === DetailEntryType.MANUAL) {
    return `${i18n.t("cb:CBEntryType.ManualPlanned", "Geplant von")} ${
      entry.meta.cf ? entry.meta.cf.name : "-"
    }`;
  }
  if (entry.data.type.indexOf("forecast") !== -1) {
    return i18n.t("cb:CBEntryType.Forecast", "Prognose");
  }
  if (entry.data.type.indexOf("sharepoint") !== -1) {
    return i18n.t("cb:CBEntryType.Sharepoint", "Sharepoint Import");
  }
  return null;
};

interface Props {
  entry: AssetCashBudgetEntry;
  showAccountData?: boolean;
  showCategory?: boolean;
  hideEntityData?: boolean;
  simple?: boolean;
  hideAttachments?: boolean;
  hideComments?: boolean;
  isUploading?: boolean;
}

const CashBudgetDetailEntryCardContent = (props: Props) => {
  const { entry, hideEntityData, simple } = props;
  const [expanded, setExpanded] = useState(false);
  const [showTruncated, setShowTruncated] = useState(false);
  const [collapsedComments, setCollapsedComments] = useState([]);

  const toggleLines = () => setExpanded(!expanded);

  const usedEntity = CashBudgetUtils.getAllEntities()?.find((e) =>
    e.banks.find((acc) => acc._id === entry.data.bankAccount)
  );
  const usedObject = usedEntity?.objects.find(
    (e) => e._id === entry.data.objectId
  );

  const comments = entry.data.comments
    ? Object.entries(entry.data.comments)
        .filter((entry) => entry[1] !== null)
        .sort((a, b) =>
          LexoRank.parse(a[1].orderIndex).compareTo(
            LexoRank.parse(b[1].orderIndex)
          )
        )
    : [];

  const account = CashBudgetUtils.findBankAccount(entry.data.bankAccount);

  const unit = CashBudgetUtils.getUnitOfBankAccount(entry.data.bankAccount);

  const relevantHistoryEntry =
    (entry.data.changeHistory || []).length > 0
      ? entry.data.changeHistory[entry.data.changeHistory.length - 1]
      : null;
  return (
    <div
      className={`cashbudget-detail-entry-card-content ${
        simple ? "simple" : ""
      }`}
    >
      <DebugDataComponent data={entry} />
      {getTopHeader(entry) ? (
        <div className={`planned-by`}>{getTopHeader(entry)}</div>
      ) : null}

      {simple === true ? null : (
        <div className={`head`}>
          <div className={`date`}>
            {moment(entry.data.date).format("DD.MM.YYYY")}
          </div>
          <div className={`fill`} />
          {props.showCategory === true || hideEntityData === true ? null : (
            <div className={`entity-object-ident text-selection-all`}>
              <div className={`entity`}>{usedEntity?.displayName}</div>
              {usedObject ? (
                <div className={`object`}>{usedObject?.displayName}</div>
              ) : null}
            </div>
          )}
        </div>
      )}
      <div className={`data-row-1`}>
        {simple && (
          <div className="date">
            {moment(entry.data.date).format("DD.MM.YYYY")}
          </div>
        )}
        <div className={`infos`}>
          {entry.data.invoiceId &&
          entry.data.receipts &&
          entry.data.receipts.length > 0 ? (
            <div className={`recipe text-selection-all`}>
              {i18n.t("cb:Label.invoiceNumberShort", "R.Nr")}:{" "}
              {entry.data.invoiceId}
            </div>
          ) : null}
          {entry.data.recipient ? (
            <div className={`recipe-text text-selection-all`}>
              {entry.data.recipient}
            </div>
          ) : null}
        </div>
        <div className={`fill`} />
        <div
          className={`amount text-selection-all ${
            entry.data.value < 0 ? "negative" : ""
          }`}
        >
          {StringUtils.formatCurrency(entry.data.value)}
        </div>
      </div>
      {props.showCategory && (
        <div className="data-row-0">
          {relevantHistoryEntry ? (
            <BFOverlay
              className="cashbudget-entry-history-overlay"
              enterable
              speaker={
                <CashBudgetHistoryOverlay historyEntry={relevantHistoryEntry} />
              }
              placement="bottomStart"
              delay={200}
            >
              <div className="category with-info">
                {LanguageService.translateLabel(
                  CashBudgetUtils.findCategory(entry.data.category)?.data
                    .displayName
                )}
              </div>
            </BFOverlay>
          ) : (
            <div className="category">
              {LanguageService.translateLabel(
                CashBudgetUtils.findCategory(entry.data.category)?.data
                  .displayName
              )}
            </div>
          )}
          <div className={`fill`} />
          {hideEntityData === true ? null : (
            <div className="entries">
              <div className={`entry-row`}>
                <div className={`unit`}>
                  {CashBudgetUtils.getLabelOfUnit(unit)}
                </div>
                <div className={`entity`}>{usedEntity?.displayName}</div>
              </div>
              {usedObject && (
                <div className={`object`}>
                  {usedObject?.id} - {usedObject?.displayName}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {entry.data.usage ? (
        <div style={{ padding: simple ? "0px 5px 0px 5px" : 6 }}>
          <div className={`transferReason`}>
            {simple === true ? null : (
              <div className={`title`}>{i18n.t("cb:Label.usage")}</div>
            )}
            <div className={`content text-selection-all`}>
              <Truncate
                onTruncate={
                  (truncated) => setShowTruncated(truncated || showTruncated) //only show when really truncated stuff
                }
                lines={!expanded && 2}
              >
                {entry.data.usage}
              </Truncate>
            </div>
            {showTruncated && (
              <div className={`toggle-lines`}>
                <a onClick={() => toggleLines()}>
                  {expanded
                    ? i18n.t("Global.Labels.less", "Weniger")
                    : i18n.t("Global.Labels.more", "Mehr")}
                </a>
              </div>
            )}
          </div>
          <CashBudgetLinks booking={props.entry} />
        </div>
      ) : null}

      {props.entry.data.asset_link?.type && (
        <div className={`show-assetlink`}>
          <BFDetailsButton
            appearance="link"
            data={{
              assetType:
                props.entry.data.asset_link?.type === "budget"
                  ? AssetTypes.Configuration.Budget
                  : props.entry.data.asset_link?.type,
              assetId: props.entry.data.asset_link?.id,
              type: props.entry.data.unit,
            }}
          >
            {ObjectRelatedUtils.getNameOfObjectRelated(
              props.entry.data.asset_link?.type
            )}{" "}
            {i18n.t("Label.show", "anzeigen")}
          </BFDetailsButton>
        </div>
      )}

      {props.hideAttachments !== true && (
        <CashBudgetAttachments
          configuration={ConfigurableContextContainerComponent}
          entry={entry}
          isUploading={props.isUploading}
        />
      )}

      {props.hideComments !== true && (
        <>
          {comments && comments.length > 0 ? (
            <div className={`comments text-selection-all`}>
              <div className={`comments-header`}>
                {i18n.t("Global.Labels.comments", "Kommentare")}
              </div>
              {comments.map(([key, comment]) => {
                const isCollapsed = collapsedComments.indexOf(key) !== -1;
                return (
                  <div
                    key={key}
                    className={`comment-entry ${
                      isCollapsed ? "collapsed" : ""
                    }`}
                    onClick={() =>
                      setCollapsedComments(
                        isCollapsed
                          ? collapsedComments.filter((e) => e !== key)
                          : [...collapsedComments, key]
                      )
                    }
                  >
                    <div className={`comment-date`}>
                      {moment(comment.createdOn).format("DD[.]MM[.]YY HH[:]mm")}
                    </div>
                    <div className={`comment-text`}>
                      {isCollapsed
                        ? comment.comment.split("\n").map((e) => (
                            <span>
                              {e}
                              <br />
                            </span>
                          ))
                        : comment.comment}
                    </div>

                    <div className={`comment-user`}>{comment.creator}</div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </>
      )}
      {props.showAccountData && (
        <div className="bank-account text-selection-all">
          <div className="main">
            <div className="bank-account-name">{account?.bankName}</div>
          </div>
          <div className="sub">
            <div className="bank-account-extra">{account?.iban}</div>
          </div>
          {account.objects?.length > 0 && (
            <div className={`objects`}>
              {account.objects
                .map((obj) => CashBudgetUtils.findObject(obj)?.displayName)
                .join(", ")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CashBudgetDetailEntryCardContent;

export const CashBudgetHistoryOverlay = ({
  historyEntry,
}: {
  historyEntry: CashBudgetEntryHistory;
}) => {
  return (
    <div className={`cb-budget-entry-history-overlay`}>
      <div className={`label`}>
        {historyEntry.type === "manual"
          ? i18n.t("cb:Label.AssignedByUser", "Zugewiesen durch Benutzer")
          : i18n.t("cb:Label.AssignedByRule", "Zugewiesen durch Regel")}
      </div>
      <div className={`info`}>
        {historyEntry.type === "manual" ? (
          <Userlabel id={historyEntry.userId} avatarSize={20} />
        ) : (
          <AssetCell
            assetType={AssetTypes.CashBudget.CBAssignmentRule}
            id={historyEntry.ruleId}
            render={(rule: CashBudgetBookingAssignmentRule) =>
              LanguageService.translateLabel(rule.data.displayName)
            }
            renderError={() => (
              <div className={`rule-deleted`}>
                {i18n.t(
                  "cb:Label.AssignmentRuleNotFound",
                  "Regel wurde bereits gelöscht"
                )}
              </div>
            )}
          />
        )}
      </div>
      <div className={`date`}>{StringUtils.formatDate(historyEntry.date)}</div>
    </div>
  );
};
