import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import StringUtils from "@/utils/StringUtils";
import {
  RentalAgreement,
  RentalAgreementCopayment,
} from "../../../TenantsInterfaces";
import { CBRentalAgreementcopaymentUpdateForm } from "../form-components/CBRentalAgreementCopaymentForm";
// import "./CBAgreementCopayment.scss";

export type CopaymentType = {
  copayment: null | RentalAgreementCopayment;
};
interface CBAgreementCopaymentProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  hideIfEmpty?: boolean;
  data: CopaymentType;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementCopayment = (props: CBAgreementCopaymentProps) => {
  if (props.hideIfEmpty && !props.data.copayment) {
    return null;
  }
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={
        <>
          <span className={`padding-right-5`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Sections.copayment.title",
              "Eigenanteil"
            )}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "sm",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBRentalAgreementcopaymentUpdateForm
                        rentalAgreement={props.rentalAgreement}
                        onClose={close}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.copayment.viewHint",
        "Informationen zum Eigenanteil"
      )}
      identifier="copayment"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      {props.data.copayment ? (
        <>
          <div className={`__flex-wrap`}>
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Form.Fields.valueByCase",
                "Eigenanteil je Fall"
              )}
              value={props.data.copayment.valueByCase}
              formatter={(value) => StringUtils.formatCurrency(value)}
            />
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Form.Fields.maxByYear",
                "Maximaler Eigenanteil pro Jahr"
              )}
              value={props.data.copayment.maxByYear}
              formatter={(value) => StringUtils.formatCurrency(value)}
            />
          </div>
          <div>
            <BFValueDisplay
              label={i18n.t("cb:RentalAgreement.Form.Fields.note", "Notiz")}
              value={props.data.copayment.note}
              formatter={(value) => <WrappedText text={value} />}
            />
          </div>
        </>
      ) : (
        <div className={`__empty no-padding`}>
          {i18n.t(
            "cb:RentalAgreement.Labels.NoCopayment",
            "Kein Eigenanteil hinterlegt"
          )}
        </div>
      )}
    </BFPDFMarkerSection>
  );
};

export default CBAgreementCopayment;
