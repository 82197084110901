import ObjectIdService from "@/utils/ObjectIdUtils";
import classNames from "classnames";
import { FormApi } from "final-form";
import { Field, FormSpy } from "react-final-form";
import FormFieldValues from "../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../components/Form/Validation/FormValidators";
import i18n from "../../../i18n";
import {
  AddressData,
  BankAccountDataEU,
  BankAccountDataUS,
  ContactPerson,
} from "../../../model/db/Contact";
import { useConstants } from "../../../redux/hooks";
import FormUtils from "../../../utils/FormUtils";
import BFInput from "../../abstract-ui/forms/input/BFInput";
import BFRadioGroup from "../../abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "../../abstract-ui/forms/radio/BfRadio";
import BFSelect from "../../abstract-ui/forms/select/BFSelect";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import ContactUtils from "../ContactUtils";
import { AddressFormPart } from "./AddressFormPart";
import { BankAccountFormPart } from "./BankAccountFormPart";
import "./CBTenantFormMain.scss";
import { ContactPersonFormPart } from "./ContactPersonFormPart";
import { EmailFormPart } from "./EmailFormPart";
import { PhoneFormPart } from "./PhoneFormPart";

interface CBTenantFormMainProps {
  prefix?: string;
  form: FormApi;
  disabled?: boolean;
  typeOptions: { label: string; value: string }[];
}
const CBTenantFormMain = (props: CBTenantFormMainProps) => {
  const constants = useConstants();
  return (
    <div className={classNames(`cb-tenant-form-main`)}>
      <div className={`section section-assignment-data`}>
        <div className={`field-row`}>
          {props.typeOptions.length !== 1 && (
            <div className={`__field`}>
              <Field
                name={(props.prefix || "") + "type"}
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <BFSelect
                    {...input}
                    label={`${i18n.t("Contact.Form.Fields.type", "Bereich")}*`}
                    validation={
                      meta.error && meta.touched
                        ? { level: "error", message: meta.error }
                        : undefined
                    }
                    data={props.typeOptions}
                    onChange={(value) => {
                      input.onChange(value);
                      props.form.mutators.setValue(
                        (props.prefix || "") + "entity",
                        null
                      );
                      props.form.mutators.setValue(
                        (props.prefix || "") + "objectId",
                        null
                      );
                      props.form.mutators.setValue(
                        (props.prefix || "") + "relations",
                        []
                      );
                    }}
                  />
                )}
              </Field>
            </div>
          )}
        </div>
      </div>
      <FormFieldValues
        names={[
          `${props.prefix || ""}salutation`,
          `${props.prefix || ""}firstName`,
          `${props.prefix || ""}lastName`,
        ]}
      >
        {([salutation, firstName, lastName]) => (
          <div className={`section section-base-data`}>
            <div
              className={`section-title __h3 margin-bottom-10 margin-top-10`}
            >
              {i18n.t("Contact.Form.Sections.contact", "Kontakt")}
            </div>
            <div className={`field-row`}>
              <div className={`__field tenant-type`}>
                <Field
                  name={(props.prefix || "") + "personType"}
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <BFRadioGroup
                      {...input}
                      inline
                      validation={
                        meta.error && meta.touched
                          ? { level: "error", message: meta.error }
                          : undefined
                      }
                      onChange={(value) => {
                        input.onChange(value);
                        if (value === "private") {
                          props.form.mutators.setValue(
                            (props.prefix || "") + "companyName",
                            null
                          );

                          props.form.mutators.setValue(
                            `${props.prefix || ""}salutationText`,
                            ContactUtils.getSalutationString({
                              personType: "private",
                              salutation: salutation,
                              firstName: firstName,
                              lastName: lastName,
                            })
                          );
                        } else {
                          props.form.mutators.setValue(
                            (props.prefix || "") + "firstName",
                            null
                          );
                          props.form.mutators.setValue(
                            (props.prefix || "") + "lastName",
                            null
                          );
                          props.form.mutators.setValue(
                            (props.prefix || "") + "salutation",
                            null
                          );

                          props.form.mutators.setValue(
                            `${props.prefix || ""}.salutationText`,
                            ContactUtils.getSalutationString({
                              personType: "organization",
                            })
                          );
                        }
                      }}
                    >
                      <BfRadio disabled={props.disabled} value={"organization"}>
                        {i18n.t(
                          "Contact.Form.Fields.personTypeOrganization",
                          "Firma"
                        )}
                      </BfRadio>
                      <BfRadio disabled={props.disabled} value={"private"}>
                        {i18n.t(
                          "Contact.Form.Fields.personTypePrivate",
                          "Person"
                        )}
                      </BfRadio>
                    </BFRadioGroup>
                  )}
                </Field>
              </div>
            </div>
            <FormFieldValues names={[(props.prefix || "") + "personType"]}>
              {([personType]) => (
                <>
                  {personType === "organization" && (
                    <>
                      <div className={`__field companyName`}>
                        <Field
                          name={(props.prefix || "") + "companyName"}
                          validate={FormValidators.compose(
                            FormValidators.required(),
                            FormValidators.max(150)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              disabled={props.disabled}
                              {...input}
                              label={`${i18n.t(
                                "Contact.Form.Fields.companyName",
                                "Name der Firma"
                              )}*`}
                              onChange={(value) => {
                                input.onChange(value);
                                props.form.mutators.setValue(
                                  `${props.prefix || ""}salutationText`,
                                  ContactUtils.getSalutationString({
                                    personType: "organization",
                                  })
                                );
                              }}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </>
                  )}
                  {personType === "private" && (
                    <div className={`field-row`}>
                      <div className={`__field __flex-1`}>
                        <Field name={(props.prefix || "") + "salutation"}>
                          {({ input, meta }) => (
                            <BFSelect
                              disabled={props.disabled}
                              {...input}
                              label={`${i18n.t(
                                "Contact.Form.Fields.salutation",
                                "Anrede"
                              )}`}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              data={FormUtils.getSalutationOptions()}
                              onChange={(value) => {
                                input.onChange(value);
                                props.form.mutators.setValue(
                                  `${props.prefix || ""}salutationText`,
                                  ContactUtils.getSalutationString({
                                    personType: "private",
                                    salutation: value,
                                    firstName,
                                    lastName,
                                  })
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className={`__field  __flex-2`}>
                        <Field
                          name={(props.prefix || "") + "firstName"}
                          validate={FormValidators.compose(
                            FormValidators.max(150)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              disabled={props.disabled}
                              {...input}
                              label={`${i18n.t(
                                "Contact.Form.Fields.firstName",
                                "Vorname"
                              )}`}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              onChange={(value: string) => {
                                props.form.mutators.setValue(
                                  `${props.prefix || ""}salutationText`,
                                  ContactUtils.getSalutationString({
                                    personType: "private",
                                    salutation,
                                    firstName: value,
                                    lastName,
                                  })
                                );
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className={`__field __flex-2`}>
                        <Field
                          name={(props.prefix || "") + "lastName"}
                          validate={FormValidators.compose(
                            FormValidators.required(),
                            FormValidators.max(150)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              {...input}
                              disabled={props.disabled}
                              label={`${i18n.t(
                                "Contact.Form.Fields.lastName",
                                "Nachname"
                              )}*`}
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                              onChange={(value: string) => {
                                props.form.mutators.setValue(
                                  `${props.prefix || ""}salutationText`,
                                  ContactUtils.getSalutationString({
                                    personType: "private",
                                    salutation,
                                    firstName,
                                    lastName: value,
                                  })
                                );
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  )}
                  {personType && (
                    <div className={`__field`}>
                      <Field
                        name={`${props.prefix || ""}salutationText`}
                        validate={FormValidators.compose(
                          FormValidators.max(120)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            disabled={props.disabled}
                            {...input}
                            label={`${i18n.t(
                              "Contact.Form.Fields.SalutationText",
                              "Anredestext"
                            )}`}
                            validation={
                              meta.error && meta.touched
                                ? {
                                    level: "error",
                                    message: meta.error,
                                  }
                                : undefined
                            }
                          />
                        )}
                      </Field>
                    </div>
                  )}

                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => (
                      <>
                        {/* Notes */}
                        {(props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                        )?.helpers?.showNote && (
                          <Field
                            name={(props.prefix || "") + "note"}
                            validate={FormValidators.compose(
                              FormValidators.max(1000)
                            )}
                          >
                            {({ input, meta }) => (
                              <div className={`__field`}>
                                <BFInput
                                  disabled={props.disabled}
                                  type="textarea"
                                  {...input}
                                  labelSuffix={
                                    <BFButton
                                      disabled={props.disabled}
                                      appearance="link"
                                      size="xs"
                                      onClick={() => {
                                        props.form.mutators.setValue(
                                          (props.prefix || "") +
                                            "helpers.showNote",
                                          false
                                        );
                                        props.form.mutators.setValue(
                                          (props.prefix || "") + "note",
                                          ""
                                        );
                                      }}
                                    >
                                      {i18n.t("Global.Buttons.remove")}
                                    </BFButton>
                                  }
                                  label={`${i18n.t(
                                    "Contact.Form.Fields.Note",
                                    "Notiz"
                                  )}`}
                                  validation={
                                    meta.error && meta.touched
                                      ? {
                                          level: "error",
                                          message: meta.error,
                                        }
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                          </Field>
                        )}
                        {/* Address */}
                        {(props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                        )?.helpers?.showAddress && (
                          <AddressFormPart
                            disabled={props.disabled}
                            form={props.form}
                            prefix={props.prefix}
                          />
                        )}
                        {/* Emails */}
                        {(props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                        )?.helpers?.showEmails && (
                          <EmailFormPart
                            disabled={props.disabled}
                            form={props.form}
                            prefix={props.prefix}
                          />
                        )}
                        {/* Phones */}
                        {(props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                        )?.helpers?.showPhone && (
                          <PhoneFormPart
                            disabled={props.disabled}
                            form={props.form}
                            prefix={props.prefix}
                          />
                        )}

                        {/* BankAccounts */}
                        {(props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                        )?.helpers?.showBankAccount && (
                          <BankAccountFormPart
                            disabled={props.disabled}
                            form={props.form}
                            prefix={props.prefix}
                          />
                        )}
                        {/* ContactPersons */}
                        {(props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                        )?.helpers?.showContactPersons && (
                          <ContactPersonFormPart
                            disabled={props.disabled}
                            form={props.form}
                            prefix={props.prefix}
                          />
                        )}
                        {/* Tag */}

                        {/* Actions */}
                        <div className={`add-actions`}>
                          {!(
                            props.prefix
                              ? values[
                                  props.prefix.substring(
                                    0,
                                    props.prefix.length - 1
                                  )
                                ]
                              : values
                          )?.helpers?.showAddress && (
                            <BFButton
                              disabled={props.disabled}
                              type="button"
                              appearance={"outline"}
                              size="xs"
                              text={i18n.t(
                                "Contact.Form.Buttons.addAddress",
                                "Adresse hinzufügen"
                              )}
                              onClick={() => {
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "helpers.showAddress",
                                  true
                                );
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "address",
                                  [
                                    {
                                      id: ObjectIdService.new(),
                                      isMain: true,
                                      street: "",
                                      zip: "",
                                      city: "",
                                      country: "",
                                      additional: "",
                                    } as AddressData,
                                  ]
                                );
                              }}
                            ></BFButton>
                          )}
                          {!(
                            props.prefix
                              ? values[
                                  props.prefix.substring(
                                    0,
                                    props.prefix.length - 1
                                  )
                                ]
                              : values
                          )?.helpers?.showEmails && (
                            <BFButton
                              disabled={props.disabled}
                              type="button"
                              appearance={"outline"}
                              size="xs"
                              text={i18n.t(
                                "Contact.Form.Buttons.addEmail",
                                "E-Mail hinzufügen"
                              )}
                              onClick={() => {
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "helpers.showEmails",
                                  true
                                );
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "emails",
                                  [
                                    {
                                      id: ObjectIdService.new(),
                                      email: "",
                                      note: "",
                                    },
                                  ]
                                );
                              }}
                            ></BFButton>
                          )}
                          {!(
                            props.prefix
                              ? values[
                                  props.prefix.substring(
                                    0,
                                    props.prefix.length - 1
                                  )
                                ]
                              : values
                          )?.helpers?.showPhone && (
                            <BFButton
                              disabled={props.disabled}
                              type="button"
                              appearance={"outline"}
                              size="xs"
                              text={i18n.t(
                                "Contact.Form.Buttons.addPhone",
                                "Telefon hinzufügen"
                              )}
                              onClick={() => {
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "helpers.showPhone",
                                  true
                                );
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "phone",
                                  [
                                    {
                                      id: ObjectIdService.new(),
                                      phone: "",
                                      note: "",
                                    },
                                  ]
                                );
                              }}
                            ></BFButton>
                          )}
                          {!(
                            props.prefix
                              ? values[
                                  props.prefix.substring(
                                    0,
                                    props.prefix.length - 1
                                  )
                                ]
                              : values
                          )?.helpers?.showBankAccount && (
                            <BFButton
                              disabled={props.disabled}
                              type="button"
                              appearance={"outline"}
                              size="xs"
                              text={i18n.t(
                                "Contact.Form.Buttons.addBankAccount",
                                "Bankkonto hinzufügen"
                              )}
                              onClick={() => {
                                props.form.mutators.setValue(
                                  (props.prefix || "") +
                                    "helpers.showBankAccount",
                                  true
                                );
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "bankAccount",
                                  [
                                    constants.currency === "USD"
                                      ? ({
                                          id: ObjectIdService.new(),
                                          type: "US",
                                          accountNumber: "",
                                          routingNumber: "",
                                          accountHolder: "",
                                        } as BankAccountDataUS)
                                      : ({
                                          id: ObjectIdService.new(),
                                          type: "EU",
                                          iban: "",
                                          bic: "",
                                          bankName: "",
                                          accountHolder: "",
                                        } as BankAccountDataEU),
                                  ]
                                );
                              }}
                            ></BFButton>
                          )}
                          {!(
                            props.prefix
                              ? values[
                                  props.prefix.substring(
                                    0,
                                    props.prefix.length - 1
                                  )
                                ]
                              : values
                          )?.helpers?.showContactPersons && (
                            <BFButton
                              disabled={props.disabled}
                              type="button"
                              appearance={"outline"}
                              size="xs"
                              text={i18n.t(
                                "Contact.Form.Buttons.addContactPerson",
                                "Kontakt hinzufügen"
                              )}
                              onClick={() => {
                                props.form.mutators.setValue(
                                  (props.prefix || "") +
                                    "helpers.showContactPersons",
                                  true
                                );
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "contactPersons",
                                  [
                                    {
                                      id: ObjectIdService.new(),
                                      salutation: null,
                                      firstName: "",
                                      lastName: "",
                                      email: "",
                                      phone: "",
                                      note: "",
                                      salutationText:
                                        ContactUtils.getSalutationString({
                                          personType: "private",
                                        }),
                                    } as ContactPerson,
                                  ]
                                );
                              }}
                            ></BFButton>
                          )}
                          {!(
                            props.prefix
                              ? values[
                                  props.prefix.substring(
                                    0,
                                    props.prefix.length - 1
                                  )
                                ]
                              : values
                          )?.helpers?.showNote && (
                            <BFButton
                              disabled={props.disabled}
                              type="button"
                              appearance={"outline"}
                              size="xs"
                              text={i18n.t(
                                "Contact.Form.Buttons.addNote",
                                "Notiz hinzufügen"
                              )}
                              onClick={() => {
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "helpers.showNote",
                                  true
                                );
                                props.form.mutators.setValue(
                                  (props.prefix || "") + "note",
                                  ""
                                );
                              }}
                            ></BFButton>
                          )}
                          {/* {!values.showTags && (
                              <BFButton
                                type="button"
                                appearance={"outline"}
                                size="xs"
                                text={i18n.t(
                                  "Contact.Form.Buttons.addTag",
                                  "Tag hinzufügen"
                                )}
                                onClick={() => {
                                  props.form.mutators.setValue((props.prefix || "")+ "showTags", true);
                                  props.form.mutators.setValue((props.prefix || "")+ "tags", [""]);
                                }}
                              ></BFButton>
                            )} */}
                        </div>
                      </>
                    )}
                  </FormSpy>
                </>
              )}
            </FormFieldValues>
          </div>
        )}
      </FormFieldValues>
    </div>
  );
};

export default CBTenantFormMain;
