import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { openEditContact } from "@/modules/contacts-module/ContactSelectMenuItem";
import ContactUtils from "@/modules/contacts-module/ContactUtils";
import { isDefined } from "@/utils/Helpers";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBRentalAgreementContactForm.scss";

interface CBRentalAgreementContactFormProps {
  contactId: string;
}
const CBRentalAgreementContactForm = (
  props: CBRentalAgreementContactFormProps
) => {
  return (
    <div className={`contactOption-form`}>
      <div
        className={`__flex section-title __h3  margin-top-10  margin-bottom-10`}
      >
        <div className={`title`}>
          {i18n.t(
            "cb:RentalAgreement.Form.SectionTitle.ContactOptions",
            "Kontaktdaten"
          )}
        </div>
      </div>
      <AssetLoader
        assetType={AssetTypes.Contact}
        id={props.contactId}
        render={(contact: Contact) => (
          <div>
            <div className={`__field`}>
              <Field
                name="contactOption.addressId"
                validate={FV.compose(FV.required())}
              >
                {({ input, meta }) => (
                  <BFChooserSelect
                    {...input}
                    {...FV.getValidation(meta)}
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.addressId",
                      "Adresse"
                    )}*`}
                    hideSearch
                    data={ContactUtils.getAdressSelectOptions(contact)}
                    overlayBottomComponent={(close) => (
                      <div
                        className={`__flex __justify-center padding-left-10 padding-right-10`}
                      >
                        <BFButton
                          appearance="link"
                          onClick={() => {
                            close();
                            openEditContact(contact);
                          }}
                        >
                          {i18n.t(
                            "cb:RentalAgreement.Form.Fields.addAddress",
                            "Adresse hinzufügen"
                          )}
                        </BFButton>
                      </div>
                    )}
                  />
                )}
              </Field>
            </div>
            <div className={`__field`}>
              <Field name="contactOption.contactPersonId">
                {({ input, meta }) => (
                  <BFChooserSelect
                    {...input}
                    {...FV.getValidation(meta)}
                    cleanable
                    overlayBottomComponent={(close) => (
                      <div
                        className={`__flex __justify-center padding-left-10 padding-right-10`}
                      >
                        <BFButton
                          appearance="link"
                          onClick={() => {
                            close();
                            openEditContact(contact);
                          }}
                        >
                          {i18n.t(
                            "cb:RentalAgreement.Form.Fields.addContactPerson",
                            "Kontaktperson hinzufügen"
                          )}
                        </BFButton>
                      </div>
                    )}
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.contactPersonId",
                      "Kontaktperson"
                    )}`}
                    hideSearch
                    data={ContactUtils.getContactPersonSelectOptions(contact)}
                  />
                )}
              </Field>
            </div>
            <div className={`__field`}>
              <Field name="contactOption.mail">
                {({ input, meta }) => (
                  <BFChooserSelect
                    {...input}
                    {...FV.getValidation(meta)}
                    cleanable
                    overlayBottomComponent={(close) => (
                      <div
                        className={`__flex __justify-center padding-left-10 padding-right-10`}
                      >
                        <BFButton
                          appearance="link"
                          onClick={() => {
                            close();
                            openEditContact(contact);
                          }}
                        >
                          {i18n.t(
                            "cb:RentalAgreement.Form.Fields.addEmail",
                            "Email-Adresse hinzufügen"
                          )}
                        </BFButton>
                      </div>
                    )}
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.mail",
                      "Per E-Mail"
                    )}`}
                    hideSearch
                    data={ContactUtils.getEmailSelectOptions(contact)}
                  />
                )}
              </Field>
            </div>
            <div className={`__field`}>
              <Field name="contactOption.phone">
                {({ input, meta }) => (
                  <BFChooserSelect
                    {...input}
                    {...FV.getValidation(meta)}
                    cleanable
                    overlayBottomComponent={(close) => (
                      <div
                        className={`__flex __justify-center padding-left-10 padding-right-10`}
                      >
                        <BFButton
                          appearance="link"
                          onClick={() => {
                            close();
                            openEditContact(contact);
                          }}
                        >
                          {i18n.t(
                            "cb:RentalAgreement.Form.Fields.addPhone",
                            "Nummer hinzufügen"
                          )}
                        </BFButton>
                      </div>
                    )}
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.phone",
                      "Per Anruf"
                    )}`}
                    hideSearch
                    data={ContactUtils.getPhoneSelectOptions(contact)}
                  />
                )}
              </Field>
            </div>
            <div className={`__field`}>
              <Field name="contactOption.sms">
                {({ input, meta }) => (
                  <BFChooserSelect
                    {...input}
                    {...FV.getValidation(meta)}
                    cleanable
                    overlayBottomComponent={(close) => (
                      <div
                        className={`__flex __justify-center padding-left-10 padding-right-10`}
                      >
                        <BFButton
                          appearance="link"
                          onClick={() => {
                            close();
                            openEditContact(contact);
                          }}
                        >
                          {i18n.t(
                            "cb:RentalAgreement.Form.Fields.addPhone",
                            "Nummer hinzufügen"
                          )}
                        </BFButton>
                      </div>
                    )}
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.sms",
                      "Per SMS"
                    )}`}
                    hideSearch
                    data={ContactUtils.getPhoneSelectOptions(contact)}
                  />
                )}
              </Field>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CBRentalAgreementContactForm;

export const CBRentalAgreementContactFormButton = () => {
  return (
    <Field name="contactOption" allowNull defaultValue={null}>
      {({ input, meta }) => {
        if (!isDefined(input.value)) {
          return (
            <BFButton
              type="button"
              appearance="outline"
              size="xs"
              onClick={() =>
                input.onChange({
                  contactOptionBy: "tenant",
                  contactOptionsCount: 3,
                  contactOptionsUsed: 0,
                })
              }
              text={i18n.t(
                "cb:RentalAgreement.Form.Buttons.addContactOption",
                "ContactOption hinzufügen"
              )}
            />
          );
        } else {
          return null;
        }
      }}
    </Field>
  );
};

export const CBRentalAgreementContactOptionUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    contactOption: props.rentalAgreement.data.contactOption,
  });
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementContact(
          props.rentalAgreement._id,
          values.contactOption
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t("cb:RentalAgreement.Form.SectionTitle.ContactOption", "Kontakt")
      )}
      submitText={i18n.t("Global.Buttons.save")}
      render={(formProps) => (
        <>
          <CBRentalAgreementContactForm
            contactId={props.rentalAgreement.data.tenant}
          />
        </>
      )}
    />
  );
};
